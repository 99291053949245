import React from 'react'

import _ from 'lodash'
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';

class Message extends BespokeQuoteStep {
    header = 'Send your chef a message?';
    stepName = 'message';
    icon = `<i className="fa-solid fa-comment"></i>`;
    nextText = 'When';

    canDoNextStep() {
        const { quote } = this.props.bespokeQuote;

        if (!quote.message) return false;

        return true;
    }
    
    renderMessage() {
        const { quote } = this.props.bespokeQuote;

        return (
            <>
                <small className='mb-2 d-block'>Tell your chef briefly what you're looking for. You'll have a chance to add more details later.</small>
                <textarea
                    id="quoteMessage"
                    className="form-control"
                    placeholder="What type of event are you booking for? Are you looking for a seated 3 course meal, a tasting experience or canapes? What dietary requirements should the chef consider when creating a menu?"
                    rows="8"
                    value={quote.message ? quote.message : ''}
                    onChange={(e) => this.props.updateAttribute('message', e.target.value)}
                />
            </>
        )
    }

    renderStep() {
        return (
            <>
                {this.renderMessage()}
            </>
        )
    }
}

export default connect(Message);