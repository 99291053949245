(function($) {
    $(function() {
        var input = $('.guests .input_guest-email'),
            emailsContainer = $('.guests .guests-email-container'),
            form = $('.guests .form_guests');

        // Send Invitation
        form.submit(function (e) {
            e.preventDefault();
            $.ajax({
                method: 'POST',
                url: form.attr('action'),
                data: form.serialize(),
                success: function(data) {
                    $('.errors_guests-form').html("");
                    if (data.errors) {
                       $.each(data.errors, function(key, value) {
                           if (value == "The email has already been taken.") {
                               value = "You have already invited this guest";
                           }
                           $('.errors_guests-form').append("<br/><div class='alert alert-danger'>" + value + "</div>");
                       });
                    }
                    if (data.success === true && data.guest) {
                        $('.errors_guests-form').append("<br/><div class='alert alert-success'>Invitation was successfuly sent to " + data.guest.email + "</div>");
                        var template = $('.delete-form-template'),
                            action = template.attr('action');
                            clone = template.clone().removeClass('delete-form-template d-none').addClass('d-inline').attr('action', action.replace('99999', data.guest.id)),
                            span = $('<span data-id="' + data.guest.id + '">' + data.guest.email + '</span>'),
                            newEmail = span.append(clone);
                        emailsContainer.append(newEmail);
                        deleteGuest();
                        input.val('');
                    }
               },
               error: function(data) {
                   console.log(data);
                   $('.errors_guests-form').html("");
                   $('.errors_guests-form').append("<br/><div class='alert alert-danger'>Unexpected Error</div>");
               }
            });
        });

        // Delete Invitation
        function deleteGuest() {
            $('.fn_delete-guest').submit(function (e) {
                e.preventDefault();
                var deleteForm = $(this);
                $.ajax({
                    method: 'POST',
                    url: deleteForm.attr('action'),
                    data: deleteForm.serialize(),
                    success: function(data) {
                        $('.errors_guests-form').html("");
                        if (data.success === true && data.guest) {
                            emailsContainer.find('span[data-id=' + data.guest.id + ']').remove();
                        }
                    },
                    error: function(data) {
                        console.log(data);
                        $('.errors_guests-form').append("<br/><div class='alert alert-danger'>Unexpected Error</div>");
                    }
                });
            });
        };
        deleteGuest();

        // Invitation Message
        var msgInput = $('.guests .input_invitation-message'),
            msgForm = $('.guests .form_invitation-message');
        msgInput.blur(function () {
            msgForm.submit();
        });
        msgForm.submit(function (e) {
            e.preventDefault();
            $.ajax({
                method: 'POST',
                url: msgForm.attr('action'),
                data: msgForm.serialize(),
                success: function(data) {
                    $('.errors_guests-form').html("").hide();
                    $('.errors_guests-form').append("<br/><div class='alert alert-success'>Message is saved</div>").fadeIn();
                },
                error: function(data) {
                   console.log(data);
                   $('.errors_guests-form').html("");
                   $('.errors_guests-form').append("<br/><div class='alert alert-danger'>Unexpected Error</div>");
                }
            });
        });

        // Preview Invitation
        $('#invitationPreviewModal').on('show.bs.modal', function (e) {
            $('.preview-iframe').attr('src', $('.preview-iframe').attr('src'));
        })

        // Share Magic Link
        var shareBtn = $('.invitation-magiclink-share');
        if (navigator.share) {
            shareBtn.show();
            $('.invitation-magiclink-copy').hide();
        }
        shareBtn.click(function () {
            if (navigator.share) {
                navigator.share({
                    title: shareBtn.attr('data-title'),
                    text: shareBtn.attr('data-text'),
                    url: shareBtn.attr('data-link')
                })
                .catch(console.error);
            } else {
                console.log('Cannot share');
            }
        });


        $('.js-guest-toggle').on('click', function() {
            $('.js-guest-toggle').toggleClass('-open')
            $('.js-guest-container').toggleClass('-visible')
        })

    });
})(jQuery);
