(function ($) {
    $(function () {
        // set hamburger classes
        $('.js-quote-decline').on('submit', function(ev) {
            console.log({ev})
            let selectedOption = $(ev.target).find('input[type="radio"]:checked')
            let optionList = $(ev.target).find('ul')
            let error = $(ev.target).find('.error')
            console.log({selectedOption, error, optionList})
            
            // If no option selected, return error
            if (selectedOption.length == 0) {
                if (error.length == 0) optionList.after('<p class="invalid-feedback d-block">Please select one option</p>') 
                return false;
            }

            return true;
        })


        $(".js-image-upload").change(function(e) {

            for (var i = 0; i < e.originalEvent.srcElement.files.length; i++) {
        
                var file = e.originalEvent.srcElement.files[i];
        
                var img = document.createElement("img");
                var reader = new FileReader();
                reader.onloadend = function() {
                     img.src = reader.result;
                }
                reader.readAsDataURL(file);

                
                $(".js-image-upload-container img").remove();
                $(".js-image-upload-container").append(img);
            }
        });
    });
})(jQuery);