import React, { Component } from 'react';

import Auth from '../../Auth/Auth';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';
// import Config from 'Config';
import { aws } from '@/config';

class AuthStep extends BespokeQuoteStep {
    header = null;
    stepName = 'auth';
    nextText = '';

    componentDidMount() {
        this._trackStep();
        this.props.getChefCount();

        const { hasSubmitted, loading, quote, confirmation } =
            this.props.bespokeQuote;
        if (hasSubmitted && !loading && this.props.isLastStep) {
            window.location.href = `${Config.sub_dir}/my-quotes/${confirmation.quoteId}/invite-chefs`;
        }
    }

    componentDidUpdate() {
        const { hasSubmitted, loading, quote, confirmation } =
            this.props.bespokeQuote;
        if (hasSubmitted && !loading && this.props.isLastStep) {
            window.location.href = `${Config.sub_dir}/my-quotes/${confirmation.quoteId}/invite-chefs`;
        }
    }

    canDoNextStep() {
        const { userData } = this.props;
        const { quote } = this.props.bespokeQuote;

        if (userData.id && !userData.phone) {
            if (!quote.phone) return false;
        }

        return true;
    }

    renderStepFooter() {
        const { userData } = this.props;
        const { loading } = this.props.bespokeQuote;

        if (userData.id && !userData.phone) {
            return (
                <div className="bespoke-quote__footer d-flex justify-content-between align-items-center w-100">
                    <span className="d-flex flex-grow-1 justify-content-end">
                        <button
                            className="btn btn--primary"
                            onClick={this.props.submitQuote.bind(this)}
                            disabled={!this.canDoNextStep() || loading}>
                            Submit
                        </button>
                    </span>
                </div>
            );
        } else {
            return false;
        }
    }

    onChange = (event) => {
        const value = event.target.value;
        this.props.updateAttribute(event.target.name, value);
    };

    saveQuoteToSession = () => {
        const { quote } = this.props.bespokeQuote;
        sessionStorage.setItem('yh-existing-quote', JSON.stringify(quote));
        sessionStorage.setItem('redirectTo', window.location.href);
    };

    renderStep() {
        const { userData } = this.props;
        const { hasSubmitted, loading, quote, errors, quoteChefCount } =
            this.props.bespokeQuote;

        if (hasSubmitted && !loading && this.props.isLastStep) {
            return (
                <div>
                    <h6>Quote submitted successfully!</h6>
                    <p>Redirecting you to messaging with the chef...</p>
                </div>
            );
        }

        return (
            <>
                <div className="row">
                    <section className="col-12 col-lg-8 offset-lg-2">
                        {userData.id && !userData.phone ? (
                            <>
                                <div
                                    className="alert alert-light mb-4"
                                    role="alert">
                                    <p className="mb-0">
                                        <i className="fa-solid fa-lock mr-2"></i>
                                        Please provide your phone number to
                                        submit quote. We'll notify you when chef
                                        responds.
                                    </p>
                                </div>

                                <div
                                    className="form-group"
                                    key={`auth-register-field-phone`}>
                                    <h4>Phone</h4>
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text pt-0 pb-0">
                                                +44
                                            </div>
                                        </div>
                                        <input
                                            id="phone"
                                            required
                                            type="tel"
                                            name="phone"
                                            autoComplete="on"
                                            onChange={this.onChange}
                                            value={quote.phone}
                                            className={`form-control ${
                                                errors.phone ? 'is-invalid' : ''
                                            }`}
                                        />
                                    </div>

                                    {errors.phone && (
                                        <span
                                            className="invalid-feedback"
                                            role="alert"
                                            style={{ display: 'block' }}>
                                            <strong>{errors.phone}</strong>
                                        </span>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <img
                                        src={`${aws.url}/images/landing/avatars.png`}
                                        alt=""
                                        className="m-w-full w-36"
                                    />
                                    <h4 className="my-3 fs-20">
                                        {quoteChefCount > 10
                                            ? quoteChefCount
                                            : 10}
                                        + chefs match your criteria
                                    </h4>
                                </div>
                                <Auth
                                    embed={true}
                                    title="Log in or sign up to see your matches!"
                                    beforeSocialAuth={() =>
                                        this.saveQuoteToSession()
                                    }
                                    callback={() => this.props.submitQuote()}
                                />
                            </>
                        )}
                    </section>
                </div>
            </>
        );
    }
}

export default connect(AuthStep);
