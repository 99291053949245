(function ($) {
    $(function () {
        // Close quote verification callback
        $('#quote-verify').on('close.bs.alert', function () {
            axios.post('/my-quotes/forget-session');
        })
       

    });
})(jQuery);
