import React, { Component } from 'react';

import Auth from '../../Auth/Auth';
import RecommendedChefs from '../RecommendedChefs/RecommendedChefs';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';
// import Config from 'Config';
import Segment from '../../../utils/Segment';
import moment from 'moment';
import _ from 'lodash';

class Confirmation extends BespokeQuoteStep {
    header = 'Your best matches are ready';

    constructor(props) {
        super(props);

        const { bespokeQuote } = this.props,
            { recommendedChefs } = bespokeQuote.confirmation;

        this.state = {
            showRecommended:
                recommendedChefs.length && recommendedChefs.length > 0,
        };
    }

    componentDidUpdate() {}

    sendToChefs = () => {
        const { selectedChefs, quoteId } = this.props.bespokeQuote.confirmation;

        const response = axios
            .post(`/quote/add-chefs/${quoteId}`, {
                chefIds: selectedChefs,
            })
            .then((response) => {
                if (response.data.success)
                    this.setState({ showRecommended: false });
            });
    };

    renderStepFooter() {
        const { bespokeQuote } = this.props,
            { recommendedChefs } = bespokeQuote.confirmation;

        const hasRecommendedChefs =
            recommendedChefs.length &&
            recommendedChefs.length > 0 &&
            this.state.showRecommended;

        if (bespokeQuote.embed) return <></>;

        return (
            <div className="bespoke-quote__footer d-flex justify-content-between align-items-center w-100">
                <span
                    className={`d-flex flex-grow-1 ${
                        hasRecommendedChefs == true
                            ? 'justify-content-between'
                            : 'justify-content-end'
                    } `}>
                    <>
                        {hasRecommendedChefs == true ? (
                            <button
                                type="button"
                                className={`btn ${
                                    hasRecommendedChefs
                                        ? 'btn-sm'
                                        : 'btn--primary'
                                }`}
                                onClick={() =>
                                    this.setState({ showRecommended: false })
                                }
                                aria-label="Close">
                                Skip
                            </button>
                        ) : (
                            <button
                                type="button"
                                className={`btn btn--primary`}
                                data-dismiss="bespokeQuoteModal"
                                data-dismiss="modal"
                                aria-label="Close">
                                Close
                            </button>
                        )}

                        {hasRecommendedChefs == true && (
                            <button
                                type="button"
                                className="btn btn--primary"
                                onClick={this.sendToChefs}
                                aria-label="Send to chefs">
                                Send to{' '}
                                {bespokeQuote.confirmation.selectedChefs
                                    .length > 0
                                    ? `${bespokeQuote.confirmation.selectedChefs.length} more`
                                    : ''}{' '}
                                chefs
                            </button>
                        )}
                    </>
                </span>
            </div>
        );
    }

    handleClick(flow) {
        const { quote } = this.props.bespokeQuote;

        const params = {
            postcode: quote.postcode,
            mealDate: moment(quote.mealDate).format('YYYY-MM-DD'),
            guests: quote.guests,
            kids: quote.kids,
            source: 'quote',
        };

        const searchParams = new URLSearchParams(params);

        let baseUrl =
            flow == 'chefs' ? `${Config.sub_dir}/chefs` : Config.sub_dir;

        const url = `${
            window.location.origin
        }${baseUrl}?${searchParams.toString()}`;

        Segment.track('quote.click-confirmation-cta', {
            flow,
        });
        setTimeout(() => {
            window.location.href = url;
        }, 500);
    }

    renderStep() {
        const { setMenuCount, redirect, confirmation, quote } =
            this.props.bespokeQuote;
        const { emailMethod } = quote;

        if (confirmation.recommendedChefs && this.state.showRecommended)
            return <RecommendedChefs chefs={confirmation.recommendedChefs} />;

        return (
            <>
                <div className="row">
                    <div className="col-12 col-lg-8 offset-lg-2 text-center">
                        {emailMethod && emailMethod == 'account-login' && (
                            <p className="mt-3 mb-3">
                                We've just sent you an email - click the button
                                in it to login into your yhangry account.
                            </p>
                        )}

                        <p className="mb-3">
                            Our chefs usually respond in{' '}
                            <strong>24 hours</strong>. If you don't get any
                            responses, ping us back on info@yhangry.com!
                        </p>

                        <button
                            onClick={() => this.handleClick('menus')}
                            className="btn btn-secondary btn-outline mr-4">
                            Browse Menus
                        </button>

                        <button
                            onClick={() => this.handleClick('chefs')}
                            className="btn btn-secondary">
                            Browse Chefs
                        </button>

                        {/* {setMenuCount >= 3 && redirect ?
                            <>
                                <a href={redirect} className="btn btn-secondary" target="_blank">View {setMenuCount} menus</a>
                            </>
                            :
                            <>
                                <div className='main-container mt-4'>
                                    <a href={redirect ? redirect : Config.sub_dir} className="btn btn-secondary main-cta" target="_blank">Browse menus in my area</a>
                                </div>
                            </>
                        } */}

                        {/* {redirect && 
                            <p className='mt-4'><small>Redirecting to your relevant menus in 10 seconds...</small></p>
                        } */}
                    </div>
                </div>
            </>
        );
    }
}

export default connect(Confirmation);
