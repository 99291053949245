import Segment from '../../../utils/Segment';

export function closeQuote(option) {
    Segment.track('quote.looking_for', { option });
    $('#bespokeQuoteModal').modal('hide');

    // if (option == 'just browsing') {
    //     window.location.href = `${Config.sub_dir}/chefs`;
    // }

    window.parent.postMessage(
        JSON.stringify({
            redirect: 'https://yhangry.com/booking/chefs',
            delay: 0,
        }),
        'https://yhangry.com'
    );
}
