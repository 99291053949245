import React, { Component } from 'react';

import _ from 'lodash';
import moment from 'moment';
// import Config from 'Config';
// import 'react-day-picker/lib/style.css';
// import 'rc-time-picker/assets/index.css';
import Autocomplete from 'react-autocomplete';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';
import WhenEvent from '../../FoodExplorer/UserOptionsContainer/WhenEvent/WhenEvent';
import NumOfGuests from '../../FoodExplorer/UserOptionsContainer/NumOfGuests/NumOfGuests';

class WhenAndWhereAndWho extends BespokeQuoteStep {
    header = null;
    icon = `<i className="fa-solid fa-location-dot"></i>`;

    state = {
        postcodes: [],
        collapse: false,
    };

    delayAutocomplete = _.debounce((value) => {
        this.props.postcodeAutocomplete(value);
    }, 500);

    componentDidMount() {
        const { quote } = this.props.bespokeQuote;

        if (quote.mealDate) {
            this.props.getExistingQuote(quote.mealDate, null);
        }

        if (quote.postcode && quote.postcode != '') {
            this.setState({
                collapse: true,
            });
        }
    }

    showForm = () => {
        this.setState({
            collapse: false,
        });
    };

    canDoNextStep() {
        const { quote, existingQuote } = this.props.bespokeQuote;

        return (
            quote.postcode &&
            quote.mealDate &&
            quote.mealDate > 0 &&
            (!existingQuote || existingQuote.length == 0)
        );
    }

    validateStep() {
        return true;
    }

    _todaysDate = () => {
        const today = moment(new Date());

        return moment(today).toDate();
    };

    renderPostcodeLookup() {
        const { bespokeQuote } = this.props,
            { quote, options } = bespokeQuote,
            value = quote.postcode;

        return (
            <>
                {this.renderHeading('Where is your event?')}

                {this.state.collapse && (
                    <div onClick={() => this.showForm()}>
                        <p className="lead font-primary fw-medium user-options-text d-inline-block mb-0">
                            <span className="text-primary">
                                {value ? value : 'Not Selected'}
                            </span>
                            <a
                                className="user-options-edit-link ml-3"
                                onClick={() => this.showForm()}>
                                Edit
                            </a>
                        </p>
                    </div>
                )}
                {!this.state.collapse && (
                    <div className="autocomplete">
                        <Autocomplete
                            getItemValue={(item) => item}
                            items={options.postcodes}
                            renderItem={(item, isHighlighted) => (
                                <div className="autocomplete-list-item">
                                    {item}
                                </div>
                            )}
                            value={value}
                            onChange={(event, value) => {
                                this.props.updateAttribute('postcode', value);
                                this.delayAutocomplete(value);
                            }}
                            onSelect={(val) =>
                                this.props.updateAttribute('postcode', val)
                            }
                            inputProps={{
                                placeholder: 'Enter Event Postcode',
                                className: 'border-secondary',
                            }}
                        />
                    </div>
                )}
            </>
        );
    }

    updateMealDate(value) {
        this.props.getExistingQuote(value, null);
        this.props.updateAttribute('mealDate', value);
    }

    updateMultipleMealDatesBoolean = (e) => {
        const { checked } = e.target;

        this.props.updateAttribute('multipleMealDatesBoolean', checked);
    };

    renderDate() {
        const { basketData, bespokeQuote } = this.props;
        const { quote, supplyDemandRate } = bespokeQuote;

        let isUrgent = false;
        if (quote.mealDate && quote.mealDate != '') {
            var futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + 3);
            futureDate.setHours(12, 0, 0, 0);

            var selectedDate = new Date(quote.mealDate);

            if (selectedDate.getTime() <= futureDate.getTime()) {
                isUrgent = true;
            }
        }

        return (
            <>
                {this.renderHeading('When is your event?')}

                <WhenEvent
                    allowCurrentDate={true}
                    mealDate={quote.mealDate}
                    updateMealDate={this.updateMealDate.bind(this)}
                    unavailableDates={
                        this.props.bespokeQuote.chefsUnavailableDays
                    }
                />

                {isUrgent && (
                    <div
                        className="chef_popularity popularity-inline popularity-slim mt-4"
                        style={{ position: 'static' }}>
                        <div className="item" style={{ padding: '0px' }}>
                            <div className="left">
                                <i className="fa-solid fa-hourglass-start mr-2 fa-beat-fade"></i>
                            </div>
                            <div className="right">
                                <span>
                                    We have chefs available! Fill out the form
                                    so we can get you a chef ASAP.
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                {basketData.supplyDemandRate >= 75 && (
                    <div
                        className="chef_popularity popularity-inline popularity-slim mt-4"
                        style={{ position: 'static' }}>
                        <div className="item" style={{ padding: '0px' }}>
                            <div className="left">
                                <i className="fa-solid fa-fire mr-2 fa-beat-fade"></i>
                            </div>
                            <div className="right">
                                <span>
                                    Low availability of chefs on this date
                                    (demand is{' '}
                                    <b>{basketData.supplyDemandRate}%</b>{' '}
                                    higher, act fast!)
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                <form className="text-left">
                    <div className="form-group">
                        <input
                            type="checkbox"
                            name="budget-assistance"
                            id="budget-assistance"
                            checked={quote.multipleMealDatesBoolean}
                            onChange={this.updateMultipleMealDatesBoolean}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="budget-assistance">
                            I require multiple dates.
                        </label>
                    </div>
                </form>
                {quote.multipleMealDatesBoolean && (
                    <textarea
                        id="quotePreferences"
                        className="form-control mt-3"
                        placeholder="eg - I'm looking to hire a chef for the 27th and 28th of this month."
                        rows="10"
                        maxLength="255"
                        value={
                            quote.multipleMealDates
                                ? quote.multipleMealDates
                                : ''
                        }
                        onChange={(e) =>
                            this.props.updateAttribute(
                                'multipleMealDates',
                                e.target.value
                            )
                        }
                    />
                )}
            </>
        );
    }

    renderGuests() {
        const { quote } = this.props.bespokeQuote;

        return (
            <>
                {this.renderHeading('For how many?')}

                <div className="d-flex justify-content-center flex-column">
                    <div>
                        {this.renderHeading('Adults')}
                        <div style={{ width: '200px' }}>
                            <NumOfGuests
                                guests={quote.guests}
                                updateGuests={(guests) =>
                                    this.props.updateAttribute('guests', guests)
                                }
                            />
                        </div>
                    </div>
                    <hr className="hr-transparent" />
                    <div>
                        {this.renderHeading('Kids (0-12 yrs)')}
                        <div style={{ width: '200px' }}>
                            <NumOfGuests
                                guests={quote.kids}
                                updateGuests={(kids) =>
                                    this.props.updateAttribute('kids', kids)
                                }
                                is_kids={true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderExistingQuoteSummary() {
        const { existingQuote } = this.props.bespokeQuote;

        return (
            <>
                {this.renderHeading('You already have a quote on this date.')}

                <p className="mb-3" style={{ marginTop: '-10px' }}>
                    <i>
                        Select another date or click your quote below to edit.
                    </i>
                </p>

                <div className="quote-item__wrapper">
                    <div className="quote-card -search card">
                        <div className="quote-card__content">
                            <h3>{existingQuote[0].meal_date_formatted}</h3>
                            <ul
                                className="fa-ul list-unstyled"
                                style={{ marginBottom: '0px' }}>
                                <li>
                                    <span className="fa-li">
                                        <i className="fa-solid fa-coins"></i>
                                    </span>
                                    <p className="mb-1 mt-1">
                                        <strong className="price">
                                            {existingQuote[0].budget_range}
                                        </strong>{' '}
                                        Budget selected
                                    </p>
                                </li>

                                <li>
                                    <span className="fa-li">
                                        <i className="fa-solid fa-users"></i>
                                    </span>
                                    {existingQuote[0].guests} Adults
                                    {existingQuote[0].kids > 0 && (
                                        <>, {existingQuote[0].kids} Kids</>
                                    )}
                                </li>

                                <li>
                                    <span className="fa-li">
                                        <i className="fa-solid fa-location-dot"></i>
                                    </span>
                                    {existingQuote[0].postcode}
                                </li>
                                {existingQuote[0].cuisines && (
                                    <li>
                                        <span className="fa-li">
                                            <i className="fa-solid fa-utensils"></i>
                                        </span>
                                        {existingQuote[0].cuisines}
                                    </li>
                                )}
                            </ul>

                            <a
                                href={`${Config.sub_dir}/my-quotes/${existingQuote[0].id}`}
                                className="btn btn-primary mt-4 mb-2">
                                View quote
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderStep() {
        const { quote, existingQuote } = this.props.bespokeQuote;

        return (
            <>
                {this.renderPostcodeLookup()}
                <hr className="hr-slim my-4" />
                {this.renderDate()}
                {existingQuote && existingQuote.length > 0 && (
                    <>
                        <section className="mt-3">
                            {this.renderExistingQuoteSummary()}
                        </section>
                    </>
                )}
                <hr className="hr-slim my-4" />

                {(!existingQuote || existingQuote.length == 0) && (
                    <>{this.renderGuests()}</>
                )}
            </>
        );
    }
}

export default connect(WhenAndWhereAndWho);
