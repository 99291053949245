import React, { Component } from 'react'

import Slider from 'rc-slider';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';
import NumOfGuests from '../../FoodExplorer/UserOptionsContainer/NumOfGuests/NumOfGuests';
import Alert from '@/components/Alert/Alert';

class Guests extends BespokeQuoteStep {
    header = 'For how many?';
    stepName = 'guests';
    nextText = '';

    renderGuests() {
        const { quote } = this.props.bespokeQuote;

        return (
            <>
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <div>
                        {this.renderHeading('Adults')}
                        <div style={{width: '200px'}}>
                            <NumOfGuests
                                guests={quote.guests}
                                updateGuests={guests => this.props.updateAttribute('guests', guests)}
                                min={1}
                                />
                        </div>
                    </div>
                    <hr className="hr-transparent" />
                    {/* <div>
                        {this.renderHeading('Kids (0-12 yrs)')}
                        <div style={{width: '200px'}}>
                            <NumOfGuests
                                guests={quote.kids}
                                updateGuests={kids => this.props.updateAttribute('kids', kids)}
                                is_kids={true}
                                min={0}
                            />
                        </div>
                    </div> */}
                </div>

                <Alert
                    type="info"
                    text="If you have kids under 10, we'd consider two kids to be equal to one adult."
                    dismissible={false}
                />
            </>
        )
    }

    renderStep() {
        return (
            <>
                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-3">
                        {this.renderGuests()}
                    </div>
                </div>
            </>
        )
    }
}

export default connect(Guests);