import React from 'react';

import _ from 'lodash';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';
import { checkIsSelected } from '../../../utils/helpers';
import SelectablePill from '../Inputs/SelectablePill';
import CreatableSelect from 'react-select/creatable';

class Cuisines extends BespokeQuoteStep {
    // header = "What type of event are you having?"
    header = 'Select cuisines you are interested in';
    stepName = 'cuisine';
    icon = ``;
    nextText = 'Budget';
    cuisinesToShow = 11;

    canDoNextStep() {
        const { quote } = this.props.bespokeQuote;

        return quote.cuisines.length > 0;
    }

    /**
     * This function receives all the options in the multi select, this is why it looks funky
     */
    selectMultiple(options) {
        const { quote } = this.props.bespokeQuote;
        const { cuisines, cuisinesExtra } = quote;
        const cuisineOptions = this.props.bespokeQuote.options.cuisines;

        // Get popular cuisine ids from top 12 shown.
        let popularCuisines = cuisineOptions
            .slice(0, this.cuisinesToShow)
            .map((cuisine) => cuisine.id);

        // Check if we have no options already added.
        options.forEach((option) => {
            // Which array do we need to add to.
            let field = option?.__isNew__ ? 'cuisinesExtra' : 'cuisines';

            // Check if selected.
            let isSelected = checkIsSelected(option.value, quote[field]);

            // If not selected, lets add it.
            if (!isSelected)
                this.props.updateAttribute(field, option.value, true);
        });

        // Lets separate out which options are existing and which the user has type.
        let existing = options
                .filter((option) => !option?.__isNew__)
                .map((option) => option.value),
            created = options
                .filter((option) => option?.__isNew__)
                .map((option) => option.value);

        // Loop through selected cuisine, if we have an id that isn't in the popualr cuisines
        // and doesn't exist in the options, lets remove.
        cuisines.forEach((cuisine) => {
            if (
                !existing.includes(cuisine) &&
                !popularCuisines.includes(cuisine)
            )
                this.props.updateAttribute('cuisines', cuisine, true);
        });
        // Same with the custom cuisines, just we don't need to check agaisnt popular cuisines.
        cuisinesExtra.forEach((cuisine) => {
            if (!created.includes(cuisine))
                this.props.updateAttribute('cuisinesExtra', cuisine, true);
        });
    }

    renderOptions() {
        let cuisineOptions = this.props.bespokeQuote.options.cuisines.filter(
            (cuisine) => cuisine.id !== 6
        );
        const { cuisines } = this.props.bespokeQuote.quote;

        // Total number of selected options.
        const totalSelected = cuisines.length;

        const order = [46, 29, 38, 33, 97, 37, 80, 100, 86, 91, 103, 39];
        cuisineOptions = _.sortBy(cuisineOptions, (cuisine) => {
            return order.indexOf(cuisine.id) === -1
                ? 100
                : order.indexOf(cuisine.id);
        });

        return (
            <ul className="bespoke-quote__grid-3">
                {_.map(cuisineOptions, (cuisine, idx) => {
                    if (idx > this.cuisinesToShow) return null;

                    const isSelected = checkIsSelected(cuisine.id, cuisines);
                    const isDisabled = totalSelected >= 3 && !isSelected;

                    const pillClass = isDisabled
                        ? 'pill-disabled'
                        : 'pill-enabled';

                    return (
                        <SelectablePill
                            onSelect={(val) => {
                                if (!isDisabled) {
                                    this.props.updateAttribute(
                                        'cuisines',
                                        val,
                                        true
                                    );
                                }
                            }}
                            isSelected={isSelected}
                            disabled={isDisabled}
                            type="checkbox"
                            idx={cuisine.id}
                            label={cuisine.name}
                            name="cuisines"
                            class={pillClass}
                        />
                    );
                })}
            </ul>
        );
    }

    renderAutoComplete() {
        const cuisineOptions = this.props.bespokeQuote.options.cuisines;
        const { cuisines, cuisinesExtra } = this.props.bespokeQuote.quote;

        // Calculate the total number of selected options.
        const totalSelected = cuisines.length + cuisinesExtra.length;

        // Options for CreatableSelect, excluding the first 'cuisinesToShow'
        let options = cuisineOptions
            .slice(this.cuisinesToShow + 1, cuisineOptions.length)
            .map((cuisine) => ({
                label: cuisine.name,
                value: cuisine.id,
            }));

        // Combine cuisines and cuisinesExtra into one array for the value prop, excluding first 12.
        const combinedSelectedCuisines = [...cuisines, ...cuisinesExtra]
            .map((selectedCuisine) => {
                // Check if the selected cuisine is one of the first 12
                if (
                    cuisineOptions
                        .slice(0, this.cuisinesToShow)
                        .find((cuisine) => cuisine.id === selectedCuisine)
                ) {
                    return null; // Exclude this cuisine
                }
                const cuisine = cuisineOptions.find(
                    (option) => option.id === selectedCuisine
                );
                return cuisine
                    ? { label: cuisine.name, value: cuisine.id }
                    : { label: selectedCuisine, value: selectedCuisine };
            })
            .filter((cuisine) => cuisine !== null); // Filter out the null values

        return (
            <>
                <CreatableSelect
                    isMulti={true}
                    options={options}
                    value={combinedSelectedCuisines} // Set the currently selected cuisines and cuisinesExtra
                    className="multi-select"
                    classNamePrefix="multi-select"
                    placeholder="Looking for something else? E.g. Sunday Roast"
                    onChange={this.selectMultiple.bind(this)}
                    isOptionDisabled={(option) =>
                        !cuisines.includes(option.value) &&
                        !cuisinesExtra.includes(option.value) &&
                        totalSelected >= 3
                    }
                />
            </>
        );
    }

    renderStep() {
        const { cuisines, cuisinesExtra } = this.props.bespokeQuote.quote;

        // Calculate the total number of selected options.
        const totalSelected = cuisines.length + cuisinesExtra.length;

        return (
            <>
                <div className="row">
                    <section className="col-12">
                        <span
                            className="d-block mb-3"
                            style={{ textAlign: 'center', marginTop: '-10px' }}>
                            {this.renderHeading(
                                'Selected cuisines (' + totalSelected + '/3)'
                            )}
                        </span>
                        {this.renderHeading('Most popular')}
                        {this.renderOptions()}
                    </section>
                    <section className="col-12">
                        {this.renderAutoComplete()}
                        <small className="mb-2 d-block mt-4">
                            You can always explore more options later by
                            inviting chefs from a variety of cuisines.
                        </small>
                    </section>
                </div>
            </>
        );
    }
}

export default connect(Cuisines);
