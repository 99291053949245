import React, { Component } from 'react'

import Slider from 'rc-slider';
import SelectablePill from '../Inputs/SelectablePill';
import { checkIsSelected } from '../../../utils/helpers';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';

class BudgetTotal extends BespokeQuoteStep {

    header = 'Tell the chef your budget';
    icon = ``;
    nextText = '';
    min = 0;
    max = 1000;

    state = {
        price: [0, 1000],
    };

    canDoNextStep() {
        const { shareBudget, budgetRange, guests, kids } = this.props.bespokeQuote.quote;

        let people = guests + kids

        if (shareBudget != '' && budgetRange[1] * people > 0) return true;

        return false;
    }

    componentDidMount() {
        this._trackStep();

        const { budgetRange, guests, kids, minSpend } = this.props.bespokeQuote.quote;

        let minSpendVal = this.props.minSpend ? parseFloat(this.props.minSpend) : parseFloat(minSpend),
            minPp = this.props.minPp ? parseFloat(this.props.minPp) : 0,
            people = guests + kids,
            min = minPp * people < minSpendVal ? minSpendVal : minPp * people

        min = parseFloat(min.toFixed());
        
        this.min = min
        this.max = min ? (min + 100) : 200

        // this.props.updateAttribute('budget', parseFloat(minSpendVal + 100));
        // this.props.updateAttribute('budgetRange', [0, ((this.min + 100) / people)])

        // Default shareBudget to true
        this.props.updateAttribute('shareBudget', "2")
    }

    updateBudget(value) {
        const { quote } = this.props.bespokeQuote;
        const { guests, kids } = quote;

        let people = guests + kids;

        this.props.updateAttribute('budget', parseFloat(value));
        this.props.updateAttribute('budgetRange', [quote.budgetRange[0], value / people]);
    }

    renderSlider() {
        const { quote } = this.props.bespokeQuote;
        const { guests, kids } = quote;
        let people = guests + kids;

        return (
            <>
                <div className="range-slider__inputs justify-content-center">
                    <div className="range-slider__field input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text p-0 pl-3 pr-3">£</div>
                        </div>
                        <label>Budget</label>
                        <input type="number"
                            className='form-control'
                            value={quote.budget}
                            min={this.min}
                            onChange={(ev) => this.updateBudget(ev.target.value)}
                        />
                    </div>
                </div>
                {/* {quote.budget < this.min &&
                    <p className='-text-color-danger fw-bold mt-3 mb-0 text-center'>Your budget is too low. Chef minimum spend is £{this.min}</p>
                } */}

                {/* <Slider 
                    handle={this.handle}
                    value={(quote.budgetRange[1])}
                    min={this.min}
                    max={this.max}
                    tipFormatter={value => `£${value}`}
                    onChange={price => {
                        this.props.updateAttribute('budgetRange', [0, price])
                    }}
                    className="range-slider" /> */}
                    
                {/* <div className="text-center mt-2">
                    <em><i class="fa-solid fa-left-right mr-2"></i><b>DRAG</b> the sliders to adjust your budget</em>
                </div> */}
            </>
        )
    }

    renderTotal() {
        const { budgetRange, guests, kids } = this.props.bespokeQuote.quote; 

        let people = guests + kids

        if (budgetRange.length == 0) return <></>

        let upper = Math.ceil(budgetRange[1] * people).toLocaleString()

        return (
            <>
                <div className="text-center">
                    <p><strong>Total estimate: £{upper}</strong></p>
                </div>
            </>
        )
    }

    renderOptions() {
        const shareBudgetOptions =  this.props.bespokeQuote.options.shareBudget;
        const { shareBudget } = this.props.bespokeQuote.quote;

        return (
            <>
                {this.renderHeading('Share your budget with a chef?')}

                <ul className='bespoke-quote__grid-2 p-0'>
                    {_.map(shareBudgetOptions, (option, idx) => {
                        return <SelectablePill 
                            onSelect={val => {
                                this.props.updateAttribute('shareBudget', val)
                            }}
                            isSelected={checkIsSelected(idx, shareBudget)}
                            disabled={false}
                            type="radio"
                            idx={idx}
                            name="who">
                                <span>
                                    {option.label}
                                </span>
                            </SelectablePill>
                    })}
                </ul>
            </>
        )
    }

    renderStep() {
        const { guests, kids } = this.props.bespokeQuote.quote;

        let people = guests + kids

        return (
            <>
                <div className='text-center mb-3'>
                    {this.renderHeading(`Your ideal budget for <strong>${people}</strong> people is?`)}
                </div>

                {this.renderSlider()}
                {/* <hr className="hr-transparent" />
                {this.renderTotal()} */}
                <small className='mt-3 alert alert-secondary d-block text-center'>Your budget will help our chef create a menu accordingly. Chef's proposed prices depends on menu requirements, service level, travel, day of the week etc.</small>
                {/* <hr className="hr-transparent" style={{marginTop: '2rem'}}/>
                {this.renderOptions()} */}
            </>
        )
    }
}

export default connect(BudgetTotal);