import React, { Component } from 'react';

import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import _ from 'lodash';
import moment from 'moment';
// import Config from 'Config';
// import 'react-day-picker/lib/style.css';
import TimePicker from 'rc-time-picker';
// import 'rc-time-picker/assets/index.css';
import Autocomplete from 'react-autocomplete';
import DayPicker from 'react-day-picker/DayPicker';
import SelectablePill from '../Inputs/SelectablePill';
import { checkIsSelected } from '../../../utils/helpers';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';
import WhenEvent from '../../FoodExplorer/UserOptionsContainer/WhenEvent/WhenEvent';
import Input from '@/elements/Input';
import Alert from '@/components/Alert/Alert';

class When extends BespokeQuoteStep {
    header = 'Date and time';
    stepName = 'date-time';
    icon = ``;
    nextText = '';

    componentDidMount() {
        this._trackStep();
        const { quote } = this.props.bespokeQuote;

        if (quote.mealDate) {
            this.props.getExistingQuote(quote.mealDate, null);
        }
    }

    canDoNextStep() {
        const { quote, existingQuote } = this.props.bespokeQuote;

        return (
            quote.mealDate &&
            quote.timeOfDay.length > 0 &&
            (!existingQuote || existingQuote.length == 0)
        );
    }

    updateMultipleMealDatesBoolean = (value) => {
        this.props.updateAttribute('multipleMealDatesBoolean', value);
    };

    updateMealDate(value) {
        const { quote } = this.props.bespokeQuote;

        this.props.getSupplyDemand(value, null);
        this.props.getExistingQuote(value, null);
        this.props.updateAttribute('mealDate', value);
    }

    renderDate() {
        const { basketData } = this.props;
        const { quote, supplyDemandRate } = this.props.bespokeQuote;

        let isUrgent = false;
        if (quote.mealDate != '') {
            var futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + 3);
            futureDate.setHours(12, 0, 0, 0);

            var selectedDate = new Date(quote.mealDate);

            if (selectedDate.getTime() <= futureDate.getTime()) {
                isUrgent = true;
            }
        }

        return (
            <>
                {this.renderHeading('When is your event?')}

                <div className="d-flex align-items-end">
                    <Input
                        inputType="event-date"
                        info="Add your best guess if you don't know"
                        value={quote.mealDate}
                        handleChange={(value) => this.updateMealDate(value)}
                        allowCurrentDate={true}
                        unavailableDates={
                            this.props.bespokeQuote.chefsUnavailableDays
                        }
                        placeholder="Select Event Date"
                        className="m-0"
                        inputClass="mt-2"
                    />

                    <a
                        className="btn btn-link border-bottom border-secondary rounded-0 text-transform-none ml-4 px-0"
                        onClick={() => this.updateMultipleMealDatesBoolean(1)}
                        style={{ letterSpacing: 'normal' }}>
                        I require multiple dates
                    </a>
                </div>

                {isUrgent && (
                    <Alert
                        type="info"
                        title="We have chefs available! Fill out the form so we can get you a chef ASAP."
                        dismissible={false}
                        customIcon={
                            <i className="fa-solid fa-hourglass-start fa-beat-fade fs-18 -text-color-grey-900" />
                        }
                        className="mt-4"
                    />
                )}

                {basketData.supplyDemandRate >= 75 && (
                    <Alert
                        type="info"
                        title={`Low availability of chefs on this date (demand is ${basketData.supplyDemandRate}% higher, act fast!)`}
                        dismissible={false}
                        customIcon={
                            <i className="fa-solid fa-fire fa-beat-fade fs-18 -text-color-grey-900" />
                        }
                        className="mt-4"
                    />
                )}

                {quote.multipleMealDatesBoolean && (
                    <>
                        <Alert
                            type="info"
                            className='mt-4 '
                            dismissible={false}
                            text="Select the first date in the date field. Add other info in the comments section."
                        />
                        <textarea
                            id="quotePreferences"
                            className="form-control mt-3"
                            placeholder="eg - I'm looking to hire a chef for the 27th and 28th of this month."
                            rows="10"
                            maxLength="255"
                            value={
                                quote.multipleMealDates
                                    ? quote.multipleMealDates
                                    : ''
                            }
                            onChange={(e) =>
                                this.props.updateAttribute(
                                    'multipleMealDates',
                                    e.target.value
                                )
                            }
                        />
                    </>
                )}
            </>
        );
    }

    renderTimeOfDay() {
        const timeOfDayOptions = this.props.bespokeQuote.options.timeOfDay;
        const { timeOfDay } = this.props.bespokeQuote.quote;

        return (
            <>
                {this.renderHeading('What time?')}

                <ul className="bespoke-quote__grid-3">
                    {_.map(timeOfDayOptions, (label, idx) => {
                        return (
                            <SelectablePill
                                className="-blocks"
                                onSelect={(val) => {
                                    this.props.updateAttribute(
                                        'timeOfDay',
                                        val,
                                        true
                                    );

                                    this.props.nextStep();
                                }}
                                isSelected={checkIsSelected(idx, timeOfDay)}
                                disabled={false}
                                type="checkbox"
                                idx={idx}
                                key={idx}
                                label={label}
                                name="time-of-day"
                            />
                        );
                    })}
                </ul>
            </>
        );
    }

    renderExistingQuoteSummary() {
        const { existingQuote } = this.props.bespokeQuote;

        return (
            <>
                {this.renderHeading('You already have a quote on this date.')}

                <p className="mb-3" style={{ marginTop: '-10px' }}>
                    <i>
                        Select another date or click your quote below to edit.
                    </i>
                </p>

                <div className="quote-item__wrapper">
                    <div className="quote-card -search card">
                        <div className="quote-card__content">
                            <h3>{existingQuote[0].meal_date_formatted}</h3>
                            <ul
                                className="fa-ul list-unstyled"
                                style={{ marginBottom: '0px' }}>
                                <li>
                                    <span className="fa-li">
                                        <i className="fa-solid fa-coins"></i>
                                    </span>
                                    <p className="mb-1 mt-1">
                                        <strong className="price">
                                            {existingQuote[0].budget_range}
                                        </strong>{' '}
                                        Budget selected
                                    </p>
                                </li>

                                <li>
                                    <span className="fa-li">
                                        <i className="fa-solid fa-users"></i>
                                    </span>
                                    {existingQuote[0].guests} Adults
                                    {existingQuote[0].kids > 0 && (
                                        <>, {existingQuote[0].kids} Kids</>
                                    )}
                                </li>

                                <li>
                                    <span className="fa-li">
                                        <i className="fa-solid fa-location-dot"></i>
                                    </span>
                                    {existingQuote[0].postcode}
                                </li>
                                {existingQuote[0].cuisines && (
                                    <li>
                                        <span className="fa-li">
                                            <i className="fa-solid fa-utensils"></i>
                                        </span>
                                        {existingQuote[0].cuisines}
                                    </li>
                                )}
                            </ul>

                            <a
                                href={`${Config.sub_dir}/my-quotes/${existingQuote[0].id}`}
                                className="btn btn-primary mt-4 mb-2">
                                View quote
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderStep() {
        const { quote, existingQuote } = this.props.bespokeQuote;

        return (
            <>
                <div className="row">
                    <section className="col-12 col-lg-12 mb-4">
                        {this.renderDate()}
                    </section>
                    {(!existingQuote || existingQuote.length < 1) && quote.mealDate && (
                        <section className="col-12 col-lg-12 mb-4">
                            {this.renderTimeOfDay()}
                        </section>
                    )}
                    {existingQuote && existingQuote.length > 0 && (
                        <>
                            <section className="col-12 col-lg-12">
                                {this.renderExistingQuoteSummary()}
                            </section>
                        </>
                    )}
                </div>
            </>
        );
    }
}

export default connect(When);
