import React, { Component } from 'react';

import { BespokeQuoteStep, connect } from './BespokeQuoteStep';
import Input from '@/elements/Input';

class ChefPreferences extends BespokeQuoteStep {
    header = 'What does a 5 star experience look like?';
    stepName = 'chef-preferences';
    icon = `<i className='fa-solid fa-carrot'></i>`;
    nextText = '';

    canDoNextStep() {
        const { quote } = this.props.bespokeQuote;

        // Check if typeOfEvent exists.
        if (!quote.chefEngagement || !quote.chefPreparation) return false;

        return true;
    }

    updateServingType(type) {
        this.props.updateAttribute('vibe', type, true);
    }

    renderAllOptions() {
        const {
            chef_engagement: chefEngagementOptions,
            chef_preparation: chefPreparationOptions,
        } = this.props.bespokeQuote.options;

        const { chefEngagement, chefPreparation } =
            this.props.bespokeQuote.quote;

        return (
            <>
                <Input
                    inputType="checkbox"
                    label={'Chef engagement / storytelling'}
                    labelClass="fw-bold"
                    className="gap-6"
                    optionClass="-button h-full"
                    cols={3}
                    options={_.map(chefEngagementOptions, (label, key) => {
                        return { label, value: key };
                    })}
                    value={chefEngagement}
                    handleChange={(value) =>
                        this.props.updateAttribute('chefEngagement', value)
                    }
                />

                <Input
                    inputType="checkbox"
                    label={'Chef cooking and preparation'}
                    info="(hint: chefs can do most of these things if the menu allows it. Fine dining menus need a lot of prep beforehand, but casual food can be cooked from scratch at your venue) "
                    labelClass="fw-bold"
                    className="gap-6"
                    optionClass="-button h-full"
                    cols={3}
                    options={_.map(chefPreparationOptions, (label, key) => {
                        return { label, value: key };
                    })}
                    value={chefPreparation}
                    handleChange={(value) =>
                        this.props.updateAttribute('chefPreparation', value)
                    }
                />
            </>
        );
    }

    renderStep() {
        return <>{this.renderAllOptions()}</>;
    }
}

export default connect(ChefPreferences);
