import React from 'react'

import SelectablePill from '../Inputs/SelectablePill';
import { checkIsSelected } from '../../../utils/helpers';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';

class PreferredContact extends BespokeQuoteStep {
    header = 'Your preferred contact method?';
    stepName = 'contact';

    canDoNextStep() {
        const {contactType, previousEvent} = this.props.bespokeQuote.quote;

        return contactType != null && previousEvent != null;
    }
    
    componentDidMount() {
        this._trackStep();
    }

    updateContactType(value) {
        this.props.updateAttribute('contactType', value);
    }

    updatePreviousEvent(value) {
        this.props.updateAttribute('previousEvent', value);
        this.props.nextStep();
    }

    renderContactOptions() {
        const { contact } = this.props.bespokeQuote.options;
        const selectedContactType = this.props.bespokeQuote.quote.contactType;

        return (
            <>
                {this.renderHeading('How do you want chefs to primarily reach out:')}

                <ul className='bespoke-quote__grid-2'>
                    {_.map(contact, (result) => {
                        return (
                            <SelectablePill
                                type='radio'
                                disabled={false}
                                idx={result.value}
                                className='-blocks'
                                name='contact-type'
                                label={result.label}
                                onSelect={this.updateContactType.bind(this)}
                                isSelected={checkIsSelected(result.value, selectedContactType)}>
                            </SelectablePill>
                        );
                    })}
                </ul>
            </>
        );
    }

    renderPreviousEvent() {
        const { previousEvent } = this.props.bespokeQuote.options;
        const selectedPreviousEvent = this.props.bespokeQuote.quote.previousEvent;

        return (
            <>
                {this.renderHeading('Have you attended a yhangry event in the past?')}

                <ul className='bespoke-quote__grid-2'>
                    {_.map(previousEvent, (result) => {
                        return (
                            <SelectablePill
                                type='radio'
                                disabled={false}
                                idx={result.value}
                                className='-blocks'
                                label={result.label}
                                name='previous-event-type'
                                onSelect={this.updatePreviousEvent.bind(this)}
                                isSelected={checkIsSelected(result.value, selectedPreviousEvent)}>
                            </SelectablePill>
                        );
                    })}
                </ul>
            </>
        );
    }
    
    renderStep() {
        const contactType = this.props.bespokeQuote.quote.contactType;
        return (
            <>
                {this.renderContactOptions()}

                {contactType !== null &&
                    this.renderPreviousEvent()
                }
            </>
        )
    }
}

export default connect(PreferredContact);