import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    isEmbed,
    fetchCuisines,
    updateQuoteValue,
    updateAttribute,
    getChefCount,
    fetchUnavailableDays,
    nextStep,
    toStep,
    getExistingQuote,
    updateExistingQuote,
    submitQuote,
} from '../../actions/BespokeQuoteActions';

import Cookies from 'js-cookie';
import BudgetTotal from './Steps/BudgetTotal';
import TypeOfChefAndBudget from './Steps/TypeOfChefAndBudget';
import AuthStep from './Steps/AuthStep';
import Dietary from './Steps/Dietary';
import Cuisines from './Steps/Cuisines';
import Segment from '../../utils/Segment';
import Confirmation from './Steps/Confirmation';
import ExistingQuote from './Steps/ExistingQuote';
import TypeOfService from './Steps/TypeOfService';
import WhenAndWhereAndWho from './Steps/WhenAndWhereAndWho';
import Protection from './Protection/Protection';
import ProgressBar from './ProgressBar/ProgressBar';
import PreferredContact from './Steps/PreferredContact';
import Representative from './Representative/Representative';
import { isLoggedIn, fetchUser } from '../../actions/UserActions';
import Message from './Steps/Message';
import When from './Steps/When';
import Guests from './Steps/Guests';
import Event from './Steps/Event';
import Location from './Steps/Location';
import { getSupplyDemand, fetchBasket } from '../../actions/BasketActions';
import Submitting from './Steps/Submitting';
import Vibe from './Steps/Vibe';
import ChefPreferences from './Steps/ChefPreferences';
import Equipment from './Steps/Equipment';

class BespokeQuote extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasMessage: false,
        };

        this.fetchUserAndContinue = this.fetchUserAndContinue.bind(this);
    }

    _getStepCount() {
        const { userData, bespokeQuote } = this.props;

        let stepCount = 10;

        // if logged in, we don't need final step to remove one from count
        if (isLoggedIn() && userData.id && userData.phone)
            stepCount = stepCount - 1;

        // if message was initialised, then increase as we want to count the initial step
        if (this.state.hasMessage) stepCount = stepCount + 1;

        if (this.props.chefId || bespokeQuote.quote.chefId) {
            stepCount = 6;

            // If not logged in, add the Auth step
            if (!isLoggedIn()) stepCount = stepCount + 1;
        }

        return stepCount;
    }

    renderSteps() {
        const { userData, bespokeQuote } = this.props;
        const { existingQuote, typeOfEvent } = bespokeQuote.quote;
        let isLastStepVal = isLoggedIn();

        if (isLastStepVal && userData.id && !userData.phone) {
            isLastStepVal = false;
        }

        // Direct quote flow
        if (this.props.chefId || bespokeQuote.quote.chefId) {
            switch (this.props.bespokeQuote.step) {
                case 0:
                    return <ExistingQuote />;
                case 1:
                    return (
                        <WhenAndWhereAndWho
                            isFirstStep={
                                !isLoggedIn() ||
                                !userData.id ||
                                !bespokeQuote?.existingQuote
                                    ? true
                                    : false
                            }
                        />
                    );
                case 2:
                    return (
                        <Message
                            stepsToSkipPrev={existingQuote == 1 ? 2 : false}
                            isLastStep={existingQuote == 1}
                        />
                    );
                case 3:
                    return (
                        <BudgetTotal
                            minSpend={this.props.minSpend}
                            minPp={this.props.minPp}
                            isLastStep={isLastStepVal}
                        />
                    );
                case 4:
                    return <AuthStep isLastStep={true} />;
                // Overwrite confirmation step
                case 100:
                    return <AuthStep isLastStep={true} />;
            }
        }

        switch (this.props.bespokeQuote.step) {
            case 0:
                return <Message stepsToSkipNext={2} />;
            case 1:
                return <Event isFirstStep={!this.state.hasMessage} />;
            case 2:
                return (
                    <Location
                        stepsToSkipPrev={this.state.hasMessage ? 2 : false}
                    />
                );
            case 3:
                return <When />;
            case 4:
                return <Guests />;
            case 5:
                return <TypeOfService />;
            case 6:
                return <TypeOfChefAndBudget />;
            case 7:
                return <Cuisines />;
            case 8:
                return (
                    <Dietary
                        isLastStep={isLastStepVal}
                        stepsToSkipNext={isLastStepVal ? 2 : 1}
                    />
                );
            case 9:
                return <AuthStep isLastStep={true} />;
            // Loading screen
            case 99:
                return <Submitting />;
            // Confirmation step after quote submit
            case 100:
                return <Confirmation />;
        }
    }

    async fetchUserAndContinue(existingQuote) {
        // wait for the user data to be fetched so we can conditionally load the step
        await this.props.fetchUser();

        const { userData } = this.props;

        this.props.updateQuoteValue(existingQuote);

        // if we have a phone number, then submit and continue
        // if (userData.phone) {
        // Open modal and show loading screen
        this.props.toStep(99);
        $('#bespokeQuoteModal').modal('show');

        this.props.submitQuote();

        sessionStorage.removeItem('yh-existing-quote');
        sessionStorage.removeItem('redirectTo');

        return;
        // }

        // TODO - check if we need this after christmas, if not, remove

        // if we don't have the phone number already, go to auth step
        this.props.toStep(13);

        // Open modal and show loading screen
        $('#bespokeQuoteModal').modal('show');

        sessionStorage.removeItem('yh-existing-quote');
        sessionStorage.removeItem('redirectTo');
    }

    componentDidMount() {
        const { userData } = this.props;
        // Check if we have an existing quote in session storage
        // Usually when a user has been redirected from social auth
        const session = sessionStorage.getItem('yh-existing-quote');
        if (session && isLoggedIn()) {
            const existingQuote = JSON.parse(session);

            if (!userData.hasLoaded) {
                // separate function to wait for user data to be fetched
                this.props.fetchUser().then(() => {
                    this.fetchUserAndContinue(existingQuote);
                });
                return;
            }
            return;
        }

        this.props.fetchCuisines();
        this.props.fetchUser();
        this.props.fetchBasket();
        this._trackQuoteStart();
        // $('#bespokeQuoteModal').modal('show')

        if (this.props.postcode) {
            this.props.updateAttribute('postcode', this.props.postcode);
        }

        if (this.props.chefId) {
            this.props.updateAttribute('chefId', parseInt(this.props.chefId));
            this.props.fetchUnavailableDays(this.props.chefId);
        }

        window.addEventListener('message', (event) => {
            const message = event.data.toString();

            // if message doesn't contain postcode attribute, return early
            if (message.indexOf('postcode') < 0) return;
            const messageJson = JSON.parse(message);

            if (messageJson.hasOwnProperty('postcode')) {
                this.props.updateAttribute('postcode', messageJson.postcode);
            }
        });

        if (this.props.embed) {
            this.props.isEmbed();
            this.props.toStep(1);
        }

        if (Cookies.get('yh-bespoke-quote') || this.props.autoShow == false)
            return false;

        setTimeout(() => {
            // Don't show if customer login modal is open
            if ($('#customerLoginModal').hasClass('show')) return;

            $('#bespokeQuoteModal').modal('show');
            Cookies.set('yh-bespoke-quote', true);
        }, this.props.autoShowDelay ?? 5000);
    }

    _trackQuoteStart() {
        const self = this;
        const { bespokeQuote } = this.props;

        $('#bespokeQuoteModal').on('show.bs.modal', function (e) {
            setTimeout(function () {
                $('.modal-backdrop').addClass('bespoke-quote__bg');
            });

            const button = $(e.relatedTarget); // Button that triggered the modal
            const validate = button.data('validate'); // Extract info from data-* attributes
            if (button && validate)
                self.props.updateAttribute('verified', validate);

            const mealDate = button.data('mealdate'); // Extract info from data-* attributes
            if (button && mealDate)
                self.props.updateAttribute('mealDate', mealDate);

            const postcode = button.data('postcode'); // Extract info from data-* attributes
            if (button && postcode)
                self.props.updateAttribute('postcode', postcode);

            const guests = button.data('guests'); // Extract info from data-* attributes
            if (button && guests) self.props.updateAttribute('guests', guests);

            const kids = button.data('kids'); // Extract info from data-* attributes
            if (button && kids) self.props.updateAttribute('kids', kids);

            const source = button.data('source'); // Extract info from data-* attributes
            if (button && source) self.props.updateAttribute('source', source);

            const cta_text = button.data('text') ?? button.text(); // Extract info from data-* attributes
            if (button && cta_text)
                self.props.updateAttribute('ctaText', cta_text);

            const message = button.data('message'); // Extract info from data-* attributes
            console.log('step', bespokeQuote.step);
            if (button && message) {
                self.setState({ hasMessage: true });
                self.props.toStep(0);
            } else if (bespokeQuote.step == 0) {
                self.props.toStep(1);
            }

            const chefId = button.data('chef-id'); // Extract info from data-* attributes

            if (button && chefId) {
                self.props.updateAttribute('chefId', parseInt(chefId));
                self.props.fetchUnavailableDays(chefId);
            }

            const quoteChefMinSpend = button.data('min-spend'); // Extract info from data-* attributes

            if (button && quoteChefMinSpend > 0) {
                self.props.updateAttribute(
                    'minSpend',
                    parseInt(quoteChefMinSpend)
                );
            }

            const quoteChefMinPp = button.data('min-pp'); // Extract info from data-* attributes

            if (button && quoteChefMinPp > 0) {
                self.props.updateAttribute('minPp', parseInt(quoteChefMinPp));
            }

            const setMenuId = button.data('set-menu-id'); // Extract info from data-* attributes

            if (button && setMenuId && setMenuId != '') {
                self.props.updateAttribute('setMenuId', parseInt(setMenuId));
            }

            Segment.track('quote.started', {
                step: bespokeQuote.step + 1,
                source: source,
            });

            Segment.track('quote.step', {
                step: bespokeQuote.step + 1,
                source: source,
            });
        });

        $('#bespokeQuoteModal').on('hidden.bs.modal', function (e) {
            self.setState({ hasMessage: false });
        });
    }

    renderBody() {
        const { userData, bespokeQuote } = this.props;
        let isLastStepVal = isLoggedIn();

        if (isLastStepVal && userData.id && !userData.phone) {
            isLastStepVal = false;
        }

        return (
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header flex-column">
                        <Representative
                            step={bespokeQuote.step}
                            isLastStepVal={isLastStepVal}
                            quoteChefCount={bespokeQuote.quoteChefCount}
                            embed={this.props.embed}
                        />
                        <ProgressBar
                            step={bespokeQuote.step}
                            total={this._getStepCount()}
                        />

                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <span>
                                {/* {this.props.bespokeQuote.step == 0 && (
                                    <>
                                        <h4 className="modal-title">
                                            Find the perfect chef for your event. 
                                        </h4>
                                        <span>Get free quotes within minutes.</span>
                                    </>
                                )} */}

                                {/* {this.props.bespokeQuote.step != 0 && !this.props.bespokeQuote.hasSubmitted && (
                                    <>
                                        <h4 className="modal-title">
                                            Step {this.props.bespokeQuote.step + 1} out of {this._getStepCount()}
                                        </h4>
                                    </>
                                )} */}
                            </span>
                        </div>
                    </div>
                    {this.renderSteps()}
                </div>
                <Protection
                    step={bespokeQuote.step}
                    isLastStepVal={isLastStepVal}
                    quoteChefCount={bespokeQuote.quoteChefCount}
                />
            </div>
        );
    }

    render() {
        const { bespokeQuote } = this.props;

        if (this.props.embed) return this.renderBody();

        return (
            <div
                className="modal fade bespoke-quote-modal"
                data-backdrop="static"
                id="bespokeQuoteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="ModalTitle"
                aria-hidden="true">
                {this.renderBody()}
            </div>
        );
    }
}

const mapStateToProps = ({ bespokeQuote, userData, basketData }) => {
    return { bespokeQuote, userData, basketData };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchBasket,
            isEmbed,
            fetchCuisines,
            updateQuoteValue,
            updateAttribute,
            fetchUser,
            getChefCount,
            fetchUnavailableDays,
            nextStep,
            toStep,
            getSupplyDemand,
            getExistingQuote,
            updateExistingQuote,
            submitQuote,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BespokeQuote);
