const { ClipboardItem } = window;
import Cookies from "js-cookie";

(function ($) {
    $(function () {
        if (window.hasOrder) {
            Cookies.set("yh_has_order", true, { expires: 365 });
        }

        if (dataLayer) {
            dataLayer.push({ event: "optimize.activate" });
        }
    });
})(jQuery);
