import React from 'react';

/**
 *
 * @param boolean isSelected
 * @param boolean disabled
 * @param 'radio|checkbox' type
 * @param number idx
 * @param string icon
 * @param string label
 *
 * @returns
 */
export default function SelectableImage(props) {
    return (
        <li
            className={`selectable-image-wrapper ${
                props.className ? props.className : 'md:w-1/4 w-1/2'
            } ${props.isSelected ? '-selected' : '-unselected'}`}>
            <span className="form-styling">
                <input
                    className="radio-primary"
                    onClick={() => props.onSelect(props.idx)}
                    disabled={props.disabled}
                    type={props.type}
                    id={'quote-type' + props.idx}
                    name="quote-type"
                    value={props.idx}
                    readOnly
                    checked={props.isSelected ? true : false}></input>
                <label
                    htmlFor={'quote-type' + props.idx}
                    className={`selectable-image-item rounded-lg ${
                        props.isSelected ? '-active' : ''
                    } custom-label`}>
                    <div
                        className={`selectable-image__img ${
                            props.smallImage ? '-small' : ''
                        }`}>
                        <img
                            data-sizes="auto"
                            data-src={props.image
                                .replace(/ /g, '-')
                                .replace(/é/i, 'e')}
                            alt={props.label}
                            className="lazyload blur-up img-fluid"
                        />
                    </div>
                    <div className="selectable-image__details">
                        <p className="mb-1">{props.label}</p>
                    </div>
                    {props.isSelected && (
                        <i className="fa-solid fa-circle-check -check"></i>
                    )}
                </label>
            </span>
        </li>
    );
}
