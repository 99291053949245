import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '6406fa68-267f-48a1-9406-7240a0a442dd',
    clientToken: 'pube9db792939dbc95f3cd2d293f88b58f2',
    site: 'datadoghq.eu',
    service: 'yhangry-booking',
    env: import.meta.env.VITE_APP_ENV,
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 5,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});
