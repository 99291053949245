import React from 'react';
import { useSelector } from 'react-redux';
// import Config from 'Config';

function ChefItem({ chef, isCarousel = false, groupSize = 3 }) {
    let coreCuisnes = [];

    if (chef.cuisines && chef.cuisines.length > 0) {
        coreCuisnes = chef.cuisines.filter((cuisine, idx) => idx < 5);
        coreCuisnes = coreCuisnes.map(({ name }) => name);
    }

    return (
        <div
            className={
                isCarousel
                    ? `col-6 col-md-${12 / groupSize} chef-item__wrapper`
                    : `chef-item`
            }>
            <div
                className={
                    isCarousel ? `chef-item chef-item-carousel` : `chef-item`
                }
                key={`chef-item-${chef.id}`}>
                <div className="row">
                    <div className="col-12">
                        <a
                            href={`${Config.sub_dir}/chef/${chef.slug}`}
                            className="chef-item__handler">
                            <div className="d-flex w-100">
                                <div>
                                    {(chef.chef_image || chef.thumbnail) && (
                                        <div className="chef-item__image">
                                            <img
                                                className="rounded-circle"
                                                fetchpriority="high"
                                                src={
                                                    chef.thumbnail
                                                        ? chef.thumbnail
                                                        : chef.chef_image
                                                }
                                                alt={`Chef ${chef.name}'s profile image`}
                                            />
                                        </div>
                                    )}

                                    {!chef.chef_image && !chef.thumbnail && (
                                        <div className="chef-item__image svg-image">
                                            <i className="fa-regular fa-user"></i>
                                        </div>
                                    )}
                                </div>

                                <div className="justify-content-between ml-2">
                                    <div>
                                        <div
                                            className="chef-item__name"
                                            style={{
                                                float: 'left',
                                                width: '100%',
                                            }}>
                                            <h2>{chef.name}</h2>
                                            {chef.review != false &&
                                                chef.review > 0 && (
                                                    <div
                                                        style={{
                                                            float: 'right',
                                                        }}>
                                                        <span className="review-summary">
                                                            <i className="fa-solid fa-star -text-color-brand-primary "></i>{' '}
                                                            {chef.review.slice(
                                                                0,
                                                                -1
                                                            )}
                                                        </span>
                                                    </div>
                                                )}
                                        </div>

                                        {chef.super_chef && (
                                            <div
                                                style={{
                                                    float: 'left',
                                                    fontSize: '15px',
                                                    width: '100%',
                                                }}>
                                                <span className="-text-color-success chef-item__super">
                                                    <i className="-icon fa-solid fa-shield mr-2"></i>
                                                    <strong>Superchef</strong>
                                                </span>
                                            </div>
                                        )}

                                        {!chef.super_chef && (
                                            <div
                                                style={{
                                                    float: 'left',
                                                    fontSize: '15px',
                                                    width: '100%',
                                                    minHeight: '23px',
                                                }}></div>
                                        )}

                                        <div
                                            style={{
                                                float: 'left',
                                                fontSize: '15px',
                                                width: '100%',
                                            }}
                                            className="mt-1">
                                            {chef.jobs_count > 0 && (
                                                <>
                                                    <i className="-text-color-brand-primary -icon fa-solid fa-circle-check mr-1"></i>{' '}
                                                    {chef.jobs_count} jobs
                                                </>
                                            )}

                                            {/* {chef.reliability_score == 100 && chef.jobs_count > 0 && 
                                                <>
                                                    <span className='space'> • </span>  
                                                    <i className="-text-color-brand-primary -icon fa-solid fa-stopwatch mr-1"></i>{ chef.reliability_score }% Reliability
                                                </>
                                            }

                                            {chef.reliability_score == 100 && chef.jobs_count == 0 && 
                                                <>
                                                    <i className="-text-color-brand-primary -icon fa-solid fa-stopwatch mr-1"></i>{ chef.reliability_score }% Reliability
                                                </>
                                            } */}
                                        </div>

                                        {chef?.distance_between &&
                                            chef?.distance_between > 0 && (
                                                <div
                                                    style={{
                                                        float: 'left',
                                                        fontSize: '15px',
                                                        width: '100%',
                                                    }}
                                                    className="mt-1">
                                                    <i className="-text-color-brand-primary -icon fa-solid fa-location-dot mr-1"></i>{' '}
                                                    {chef.distance_between} km
                                                    away
                                                </div>
                                            )}

                                        <div
                                            style={{
                                                float: 'left',
                                                fontSize: '15px',
                                                width: '100%',
                                            }}
                                            className="mt-1">
                                            {(chef.min_price_per_person > 0 ||
                                                chef.min_spend > 0) && (
                                                <span>
                                                    <i className="-text-color-brand-primary -icon fa-solid fa-coins mr-2"></i>
                                                    {chef.min_price_per_person >
                                                        0 && (
                                                        <>
                                                            From{' '}
                                                            {
                                                                chef.min_price_per_person
                                                            }
                                                            pp
                                                        </>
                                                    )}
                                                    {chef.min_price_per_person >
                                                        0 &&
                                                        chef.min_spend > 0 && (
                                                            <> / </>
                                                        )}
                                                    {chef.min_spend > 0 && (
                                                        <>
                                                            £{chef.min_spend}{' '}
                                                            min
                                                        </>
                                                    )}
                                                </span>
                                            )}
                                        </div>

                                        {chef.cuisines &&
                                            chef.cuisines.length > 0 && (
                                                <div
                                                    style={{
                                                        float: 'left',
                                                        fontSize: '15px',
                                                        width: '100%',
                                                    }}
                                                    className="mt-1">
                                                    <p>
                                                        <i className="-text-color-brand-primary -icon fa-solid fa-utensils mr-2"></i>
                                                        {coreCuisnes.join(', ')
                                                            .length > 42
                                                            ? coreCuisnes
                                                                  .join(', ')
                                                                  .substring(
                                                                      0,
                                                                      42
                                                                  ) + '...'
                                                            : coreCuisnes.join(
                                                                  ', '
                                                              )}
                                                        {chef.cuisines.length -
                                                            5 >
                                                            0 && (
                                                            <>
                                                                <b>
                                                                    {' '}
                                                                    (+
                                                                    {chef
                                                                        .cuisines
                                                                        .length -
                                                                        5}
                                                                    )
                                                                </b>
                                                            </>
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                            {chef.highlight && (
                                <div className="w-100">
                                    <hr></hr>
                                    <p className="-text-color-brand-secondary-dark m-2 mb-0">
                                        {chef.highlight &&
                                        chef.highlight.length > 40
                                            ? chef.highlight.substring(0, 40) +
                                              '...'
                                            : chef.highlight}
                                    </p>
                                </div>
                            )}
                        </a>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-12">
                        <div className="btn-group d-flex">
                            <a href={`${Config.sub_dir}/chef/${chef.slug}`} className="btn btn-stone flex-grow-1" target="_blank">View profile</a>

                            <button className="btn btn-primary flex-grow-1" 
                                data-toggle="modal" 
                                data-target="#chefMessageModal" 
                                data-chef={chef.id}
                                data-validate="true"
                                ><i className="-text-color-white -icon fa-solid fa-comment"></i> Message Chef</button>   
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default React.memo(ChefItem);
