import React from 'react';
import ReactDOM from 'react-dom';
import useLazyComponent from '@/utils/useLazyComponent';
import lazySizes from 'lazysizes';

import Auth from './views/Auth/Auth';
import objectFitImages from 'object-fit-images';

import RequestWizard from '@/views/Wizards/RequestWizard/RequestWizard';
import BespokeQuoteContainer from './views/BespokeQuote/BespokeQuoteContainer';
import DirectQuoteContainer from './views/DirectQuote/DirectQuoteContainer';

// import ChefMenu from './views/MyAccount/ChefMenu';
// import CartContainer from './views/Cart/CartContainer';
// import MyAccount from './views/MyAccount/MyAccount';
// import GiftCard from './views/GiftCard/GiftCard';
// import FoodExplorerRouter from './views/FoodExplorer/FoodExplorerRouter';
// import EventBookingContainer from './views/EventBooking/EventBookingContainer';
// import Reviews from './components/Reviews/Reviews';
// import ChefQuickBookContainer from './views/ChefQuickBook/ChefQuickBookContainer';
// import ChefCalendar from './components/ChefCalendar/ChefCalendar';
// import OrderReviewContainer from './views/OrderReview/OrderReviewContainer';
// import CountdownContainer from './views/Countdown/CountdownContainer';
// import JobsBoardContainer from './views/JobsBoard/JobsBoardContainer';
// import Occasions from './views/Occasions/Occasions';
// import OccasionsForm from './views/Occasions/OccasionsForm';
// import CardPaymentContainer from './views/CardPayment/CardPaymentContainer';
const ChefMenu = useLazyComponent(() => import('./views/MyAccount/ChefMenu'));
const CartContainer = useLazyComponent(() =>
    import('./views/Cart/CartContainer')
);
const MyAccount = useLazyComponent(() => import('./views/MyAccount/MyAccount'));
const GiftCard = useLazyComponent(() => import('./views/GiftCard/GiftCard'));
const FoodExplorerRouter = useLazyComponent(() =>
    import('./views/FoodExplorer/FoodExplorerRouter')
);
const EventBookingContainer = useLazyComponent(() =>
    import('./views/EventBooking/EventBookingContainer')
);
const Reviews = useLazyComponent(() => import('./components/Reviews/Reviews'));
const ChefQuickBookContainer = useLazyComponent(() =>
    import('./views/ChefQuickBook/ChefQuickBookContainer')
);
const ChefCalendar = useLazyComponent(() =>
    import('./components/ChefCalendar/ChefCalendar')
);
const OrderReviewContainer = useLazyComponent(() =>
    import('./views/OrderReview/OrderReviewContainer')
);
const CountdownContainer = useLazyComponent(() =>
    import('./views/Countdown/CountdownContainer')
);
const JobsBoardContainer = useLazyComponent(() =>
    import('./views/JobsBoard/JobsBoardContainer')
);
const Occasions = useLazyComponent(() => import('./views/Occasions/Occasions'));
const OccasionsForm = useLazyComponent(() =>
    import('./views/Occasions/OccasionsForm')
);
const CardPaymentContainer = useLazyComponent(() =>
    import('./views/CardPayment/CardPaymentContainer')
);

// import Chat from './components/Chat';

//Food explorer component
if (document.querySelectorAll('#foodExplorer').length) {
    for (
        var i = 0;
        i < document.querySelectorAll('#foodExplorer').length;
        i++
    ) {
        var foodExplorerElem = document.querySelectorAll('#foodExplorer')[i],
            guests = foodExplorerElem.getAttribute('data-guests'),
            kids = foodExplorerElem.getAttribute('data-kids'),
            mealDate = foodExplorerElem.getAttribute('data-mealDate'),
            postcode = foodExplorerElem.getAttribute('data-postcode'),
            cuisine = foodExplorerElem.getAttribute('data-cuisine'),
            onlyExtras = foodExplorerElem.getAttribute('data-onlyExtras'),
            flow = foodExplorerElem.getAttribute('data-flow'),
            chef = foodExplorerElem.getAttribute('data-chef'),
            tags = foodExplorerElem.getAttribute('data-tags'),
            types = foodExplorerElem.getAttribute('data-types'),
            cuisines = foodExplorerElem.getAttribute('data-cuisines'),
            prices = foodExplorerElem.getAttribute('data-prices'),
            dietary = foodExplorerElem.getAttribute('data-dietary'),
            outerLondonRegex = foodExplorerElem.getAttribute(
                'data-outerLondonRegex'
            ),
            sortBy = foodExplorerElem.getAttribute('data-sortBy'),
            category = foodExplorerElem.getAttribute('data-category'),
            city = foodExplorerElem.getAttribute('data-city'),
            show = foodExplorerElem.getAttribute('data-show');

        ReactDOM.render(
            <FoodExplorerRouter
                flow={flow}
                view={onlyExtras ? 'dishes' : 'set-menu'}
                _guests={guests}
                _kids={kids}
                _mealDate={mealDate}
                _postcode={postcode}
                _cuisine={cuisine}
                _chef={chef}
                _tags={tags}
                _types={types}
                _cuisines={cuisines}
                _prices={prices}
                _dietary={dietary}
                _onlyExtras={onlyExtras}
                _outerLondonRegex={outerLondonRegex}
                _sortBy={sortBy}
                _category={category}
                _city={city}
                _show={show}
            />,
            foodExplorerElem
        );
    }
}

// Cart component
var cartElem = document.getElementById('cart'),
    cartElemMob = document.getElementById('cart-mobile');
if (cartElem && !foodExplorerElem) {
    ReactDOM.render(<CartContainer independent={true} />, cartElem);
}
if (cartElemMob && !foodExplorerElem) {
    ReactDOM.render(<CartContainer independent={true} />, cartElemMob);
}

//Event Booking component
if (document.getElementById('eventBooking')) {
    var outerLondonRegex = document
        .getElementById('eventBooking')
        .getAttribute('data-outerLondonRegex');
    ReactDOM.render(
        <EventBookingContainer _outerLondonRegex={outerLondonRegex} />,
        document.getElementById('eventBooking')
    );
}

//Food explorer component
if (document.getElementById('myAccount')) {
    ReactDOM.render(<MyAccount />, document.getElementById('myAccount'));
}

//Chef menu component
if (document.getElementById('chefMenu')) {
    ReactDOM.render(<ChefMenu />, document.getElementById('chefMenu'));
}

//Gift Card component
if (document.getElementById('giftCard')) {
    ReactDOM.render(<GiftCard />, document.getElementById('giftCard'));
}

// Auth component
var authElem = document.querySelectorAll('#authComponent');
if (authElem.length) {
    for (var i = 0; i < authElem.length; i++) {
        if (!flow || flow != 'experiences') {
            var authType = authElem[i].getAttribute('data-type');
            var embed = authElem[i].getAttribute('data-embed');
            ReactDOM.render(
                <Auth type={authType} embed={embed} />,
                authElem[i]
            );
        }
    }
}

// Occasions component
var occasions = document.getElementById('occasions');
if (occasions) {
    var types = occasions.getAttribute('data-types');
    ReactDOM.render(<Occasions occasionTypes={types} />, occasions);
}
var occasionsForm = document.getElementById('occasionsForm');
if (occasionsForm) {
    var types = occasionsForm.getAttribute('data-types');
    ReactDOM.render(<OccasionsForm occasionTypes={types} />, occasionsForm);
}

var wizard = document.getElementById('requestWizard');
if (wizard) {
    var autoShow = wizard.getAttribute('data-autoShow');
    autoShow = autoShow ? parseInt(autoShow) : false;
    var embed = wizard.getAttribute('data-embed');

    ReactDOM.render(
        <RequestWizard autoShow={autoShow} embed={embed} />,
        wizard
    );
}

var bespokeQuote = document.getElementById('bespokeQuote');
if (bespokeQuote) {
    var autoShow = bespokeQuote.getAttribute('data-autoShow');
    var embed = bespokeQuote.getAttribute('data-embed');
    var quoteChefId = bespokeQuote.getAttribute('data-chef');
    var quoteChefMinSpend = bespokeQuote.getAttribute('data-min-spend');
    var quoteChefMinPp = bespokeQuote.getAttribute('data-min-pp');
    var postcode = bespokeQuote.getAttribute('data-postcode');
    var setMenuId = bespokeQuote.getAttribute('data-set-menu-id');

    ReactDOM.render(
        <BespokeQuoteContainer
            _autoShow={autoShow}
            _embed={embed}
            _chefId={quoteChefId}
            _minSpend={quoteChefMinSpend}
            _minPp={quoteChefMinPp}
            _postcode={postcode}
            _setMenuId={setMenuId}
        />,
        bespokeQuote
    );
}

var directQuote = document.getElementById('directQuote');
if (directQuote) {
    var source = bespokeQuote.getAttribute('data-source');
    var setMenuId = bespokeQuote.getAttribute('data-set-menu-id');

    ReactDOM.render(
        <DirectQuoteContainer
            _source={source}
            _setMenuId={setMenuId}
            _disableInertia={true}
        />,
        directQuote
    );
}

var cardPayment = document.getElementById('card-payment');
if (cardPayment) {
    var initialize = cardPayment.getAttribute('data-initialize');
    var price = cardPayment.getAttribute('data-quote-price');
    var chefId = cardPayment.getAttribute('data-quote-chef-id');
    var postcode = cardPayment.getAttribute('data-quote-postcode');
    var mealDate = cardPayment.getAttribute('data-quote-meal-date');
    var guests = cardPayment.getAttribute('data-quote-guests');
    var kids = cardPayment.getAttribute('data-quote-kids');
    var isBespokeQuote = cardPayment.getAttribute('data-is-bespoke-quote');
    var isDepositable = cardPayment.getAttribute('data-is-depositable');
    var source = cardPayment.getAttribute('data-source');

    ReactDOM.render(
        <CardPaymentContainer
            _initialize={initialize}
            _price={price}
            _chefId={chefId}
            _postcode={postcode}
            _mealDate={mealDate}
            _guests={guests}
            _kids={kids}
            _isBespokeQuote={isBespokeQuote}
            _isDepositable={isDepositable}
            _source={source}
        />,
        cardPayment
    );
}

// Reviews component
var reviewsElem = document.querySelectorAll('.js-reviews');
if (reviewsElem.length > 0) {
    for (
        var reviewsElemCount = 0;
        reviewsElemCount < reviewsElem.length;
        reviewsElemCount++
    ) {
        var chefId = reviewsElem[reviewsElemCount].getAttribute('data-chef'),
            cityId = reviewsElem[reviewsElemCount].getAttribute('data-city'),
            cuisineId =
                reviewsElem[reviewsElemCount].getAttribute('data-cuisine');
        ReactDOM.render(
            <Reviews chefId={chefId} cityId={cityId} cuisineId={cuisineId} />,
            reviewsElem[reviewsElemCount]
        );
    }
}

var chefQuickBook = document.getElementById('js-chef-quick-book');
if (chefQuickBook) {
    var chefId = chefQuickBook.getAttribute('data-chef'),
        chefName = chefQuickBook.getAttribute('data-chef-name'),
        quotesCount = chefQuickBook.getAttribute('data-quotes-count'),
        viewsCount = chefQuickBook.getAttribute('data-views-count');
    ReactDOM.render(
        <ChefQuickBookContainer
            _chefId={chefId}
            _chefName={chefName}
            _quotesCount={quotesCount}
            _viewsCount={viewsCount}
        />,
        chefQuickBook
    );
}

var chefCalendarElem = document.getElementById('js-chef-calendar');
if (chefCalendarElem) {
    var chefId = chefCalendarElem.getAttribute('data-chef');
    ReactDOM.render(<ChefCalendar chefId={chefId} />, chefCalendarElem);
}

// Order review.
var orderReview = document.getElementById('js-order-review');
if (orderReview) {
    var referralCode = orderReview.getAttribute('data-chef_referral_code');
    var bookingReference = orderReview.getAttribute('data-booking_reference');
    var chefSlug = orderReview.getAttribute('data-chef_slug');
    var chefName = orderReview.getAttribute('data-chef_name');
    var customerReferralCode = orderReview.getAttribute(
        'data-customer_referral_code'
    );
    var flash = orderReview.getAttribute('data-flash');

    ReactDOM.render(
        <OrderReviewContainer
            _referralCode={referralCode}
            _bookingReference={bookingReference}
            _chefSlug={chefSlug}
            _chefName={chefName}
            _customerReferralCode={customerReferralCode}
            _flash={flash}
        />,
        orderReview
    );
}

// Countdown timer component
var countDownTimerElem = document.querySelectorAll('.js-countdown-timer');
if (countDownTimerElem.length > 0) {
    for (var count = 0; count < countDownTimerElem.length; count++) {
        var timerOnly =
            countDownTimerElem[count].getAttribute('data-timer_only');
        var targetDate =
            countDownTimerElem[count].getAttribute('data-target_date');
        var quoteCount =
            countDownTimerElem[count].getAttribute('data-quote_count');

        ReactDOM.render(
            <CountdownContainer
                _timerOnly={timerOnly}
                _targetDate={targetDate}
                _quoteCount={quoteCount}
            />,
            countDownTimerElem[count]
        );
    }
}

// var chatElem = document.getElementById('js-openai-chat');
// if (chatElem) {
//     ReactDOM.render(<Chat />, chatElem);
// }

objectFitImages();

// Jobs Board.
var jobsBoardElem = document.getElementById('js-jobs-board');
if (jobsBoardElem) {
    ReactDOM.render(<JobsBoardContainer />, jobsBoardElem);
}
