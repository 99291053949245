import React, { Component } from 'react'

import _ from 'lodash';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';
import { isLoggedIn } from '../../../actions/UserActions';
import SelectablePill from '../Inputs/SelectablePill';
import { checkIsSelected } from '../../../utils/helpers';

class ExistingQuote extends BespokeQuoteStep {
    header = 'Which event?';
    icon = ``;
    nextText = '';
    stepsToSkipNext = 2;

    componentDidMount() {
        const { userData } = this.props;

        if (!isLoggedIn() || !userData.id ) {
            this.props.nextStep();
        } else {
            this.props.getExistingQuote();
        }
    }

    componentDidUpdate(prevProps) {
        const { bespokeQuote, userData } = this.props;

        if (bespokeQuote.existingQuote && bespokeQuote.existingQuote !== prevProps.existingQuote) {
            if (!bespokeQuote?.existingQuote || bespokeQuote?.existingQuote.length <= 0) {
                this.props.nextStep();
            }
        } else if (!isLoggedIn() || !userData.id ) {
            this.props.nextStep();
        }
    }

    canDoNextStep() {
        const { quote } = this.props.bespokeQuote;

        if (quote.existingQuote == '1') {
            return quote.selectedExistingQuote ? true : false
        }

        return (quote.existingQuote != '')
    }

    nextStep() {
        const { quote } = this.props.bespokeQuote;
        
        if (quote.existingQuote == 1) {
            // do not submit now, skip to message step
            this.props.nextStep(2);
        } else {
            this.props.nextStep(1);
        }
    }

    selectQuote = (id) => {
        this.props.updateAttribute('existingQuote', "1")
        this.props.updateAttribute('selectedExistingQuote', id)
    }

    newEvent = () => {
        this.props.updateAttribute('existingQuote', "2")
        this.props.nextStep(1);
    }

    renderExistingQuoteSummary() {
        const { existingQuote, quote } = this.props.bespokeQuote;

        return (
            <>
                <div className="quote-item__wrapper row">
                    {_.map(existingQuote, (existingQuote) => {
                        return (
                            <div className='col-12 col-lg-6'>
                                <div className={`quote-card -search card ${existingQuote.id == quote.selectedExistingQuote ? '-selected' : ''}`} onClick={() => this.selectQuote(existingQuote.id)}>
                                    <div className="quote-card__content">
                                        <h3>{ existingQuote.meal_date_formatted }</h3>
                                        <ul className="fa-ul list-unstyled" style={{marginBottom: '0px'}}>
                                            <li>
                                                <span className="fa-li"><i className="fa-solid fa-coins"></i></span>
                                                <p className="mb-1 mt-1">
                                                    <strong className="price">{ existingQuote.budget_range }</strong> Budget selected
                                                </p> 
                                            </li>

                                            <li>
                                                <span className="fa-li"><i className="fa-solid fa-users"></i></span>
                                                { existingQuote.guests } Adults
                                                { existingQuote.kids > 0 &&
                                                    <>, { existingQuote.kids } Kids</>
                                                }
                                            </li>

                                            <li>
                                                <span className="fa-li"><i className="fa-solid fa-location-dot"></i></span>
                                                { existingQuote.postcode }
                                            </li>
                                            {existingQuote.cuisines &&
                                                <li>
                                                    <span className="fa-li"><i className="fa-solid fa-utensils"></i></span>
                                                    { existingQuote.cuisines }
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }

    renderOptions() {
        const existingQuoteOptions =  this.props.bespokeQuote.options.existingQuote;
        const { existingQuote } = this.props.bespokeQuote.quote;

        return (
            <>
                {this.renderHeading('Add this chef to your current quote?')}

                <ul className='bespoke-quote__grid_1 p-0'>
                    {_.map(existingQuoteOptions, (option, idx) => {
                        return <SelectablePill 
                            onSelect={val => {
                                this.props.updateAttribute('existingQuote', val)
                            }}
                            isSelected={checkIsSelected(idx, existingQuote)}
                            disabled={false}
                            type="radio"
                            idx={idx}
                            name="who"
                            class="existingQuote">
                                <span>
                                    {option.label}
                                </span>
                            </SelectablePill>
                    })}
                </ul>
            </>
        )
    }

    renderStep() {
        const { quote, existingQuote } = this.props.bespokeQuote;

        return (
            <>
                <p className='lead text-center mb-5' style={{'marginTop': '-30px'}}>
                    Request a quote for an existing event or create a 
                    <a className='text-primary cursor-pointer' onClick={() => this.newEvent()}> new event</a>.
                </p>
                <div className="row">
                    {quote.chefId && existingQuote &&
                    <>
                        <section className='col-12'>
                            {this.renderExistingQuoteSummary()}
                        </section>
                        {/* <section className='col-12 col-lg-6'>
                            {this.renderOptions()}
                        </section> */}
                    </>
                    }
                </div>
            </>
        )
    }
}

export default connect(ExistingQuote);