import React from 'react';

import { Info } from '@phosphor-icons/react';
import SelectablePill from '../Inputs/SelectablePill';
import { checkIsSelected } from '../../../utils/helpers';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';
import Breakdown from '@/components/Pricing/Breakdown';

class TypeOfChefAndBudget extends BespokeQuoteStep {
    noChefTypeOptions = [7, 2, 4, 5];
    header = this.noChefTypeOptions.includes(
        this.props.bespokeQuote.quote.typeOfEvent
    )
        ? _.filter(
              this.props.bespokeQuote.options.servingType,
              (type) => type.id == this.props.bespokeQuote.quote.typeOfEvent
          )?.[0]?.label
        : 'Type of experience';
    stepName = 'budget';
    icon = ``;
    nextText = '';
    lowestPrice = 0;
    isFineDining = false;
    // this.variant == 'B' && this.props.bespokeQuote.quote.typeOfEvent == 6;
    isPpBudget = false;
    // this.variant == 'B' &&
    // this.props.bespokeQuote.quote.typeOfEvent != 4 && // Meal Prep
    // this.props.bespokeQuote.quote.typeOfEvent != 5 && // Multi-day Chef Hire
    // this.props.bespokeQuote.quote.typeOfEvent != 7; // Cooking Class

    state = {
        toggleBudget: false,
        price: [0, 1000],
        update: false,
        type: false,
    };

    canDoNextStep() {
        const { typeOfEvent, typeOfChef, budget, guests, conciergeInterest } =
            this.props.bespokeQuote.quote;
        let budgetPp = budget / guests;

        let budgetCheck = budgetPp >= 20 && budget >= 100;

        // Check for the disqualifying condition first.
        // if (typeOfChef > 1 && budget > 100 && budgetPp >= 40 && conciergeInterest == null) {
        //     return false;
        // }

        // Then check for conditions that allow proceeding.
        if (
            (typeOfEvent == 1 && typeOfChef > 0 && budgetCheck) ||
            (typeOfEvent != 1 && budgetCheck)
        ) {
            return true;
        }

        // Default to false if neither set of conditions are met.
        return false;
    }

    componentDidUpdate(prevProps) {
        this._trackStep();
        const { quote } = this.props.bespokeQuote;
        const { typeOfChef, guests, kids } = this.props.bespokeQuote.quote;
        const typeOfChefOptions = this.isFineDining
            ? this.props.bespokeQuote.options.typeOfChefFineDining
            : this.props.bespokeQuote.options.typeOfChef;
        const selectedTypeOfEvent = this.props.bespokeQuote.quote.typeOfEvent;

        if (typeOfChef != prevProps.bespokeQuote.quote.typeOfChef) {
            let chefType = typeOfChefOptions[typeOfChef],
                people = guests + kids,
                min = Math.ceil(chefType?.default[0] * people),
                max = Math.ceil(chefType?.default[1] * people);

            this.lowestPrice = chefType.price;

            if (min < this.lowestPrice) min = this.lowestPrice;
            if (max <= min) max += 200;

            if (this.isPpBudget) {
                min = Math.ceil(min / people);
                max = Math.ceil(max / people);
                this.lowestPrice = chefType?.default?.[0];
            }

            this.props.updateAttribute('suggestedBudget', [min, max]);

            // Uncomment to auto populate average budget by default
            // let average = (min + max) / 2

            if (
                new Date(quote.mealDate).setHours(0, 0, 0, 0) ==
                new Date('2023-12-25').setHours(0, 0, 0, 0)
            ) {
                this.setState({ type: 'christmas' });
                this.lowestPrice = selectedTypeOfEvent == 2 ? 500 : 1000;
            } else if (
                new Date(quote.mealDate).setHours(0, 0, 0, 0) ==
                new Date('2023-12-31').setHours(0, 0, 0, 0)
            ) {
                this.setState({ type: 'newyear' });
                this.lowestPrice = selectedTypeOfEvent == 2 ? 500 : 1000;
            }

            this.setState({ update: true });
        }
    }

    updateBudget(value) {
        const { quote } = this.props.bespokeQuote;
        const { guests, kids } = quote;

        let people = guests + kids;
        value = parseFloat(value);

        if (this.isPpBudget) {
            this.props.updateAttribute('budget', Math.ceil(value * people));
            this.props.updateAttribute('budgetRange', [0, value]);
            return;
        }
        this.props.updateAttribute('budget', value);
        this.props.updateAttribute('budgetRange', [0, value / people]);
    }

    underBudget() {
        const { budget } = this.props.bespokeQuote.quote;

        return budget < this.lowestPrice;
    }

    renderSlider() {
        const { quote } = this.props.bespokeQuote;
        const { typeOfEvent, typeOfChef, budget, guests } = quote;

        let budgetPp = budget / guests;
        if (typeOfEvent == 1 && !typeOfChef) return <></>;

        if (!is_node) {
            $(function () {
                $('[data-toggle="tooltip"]').tooltip();
            });
        }

        return (
            <>
                <div className="range-slider__inputs mb-2 mt-2">
                    <div
                        className="range-slider__field input-group"
                        style={{ flex: '0 0 100%' }}>
                        <div className="input-group-prepend">
                            <div className="input-group-text p-0 pl-3 pr-3">
                                £
                            </div>
                        </div>
                        <label>
                            Max budget {this.isPpBudget ? 'per person' : ''}
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            value={
                                this.isPpBudget
                                    ? quote.budgetRange?.[1]
                                    : quote.budget
                            }
                            min={this.lowestPrice}
                            onChange={(ev) => {
                                this.updateBudget(ev.target.value);

                                // if (budget < 100 || budgetPp < 40) {
                                //     this.props.updateAttribute('conciergeInterest', null);
                                // }
                            }}
                        />
                    </div>
                </div>
                {this.isPpBudget && (
                    <div className="flex justify-end">
                        <Breakdown
                            className="[&_.price-item_span]:ml-2 -remove-outer-padding"
                            items={[
                                {
                                    label: `Total budget:`,
                                    value: quote.budget,
                                    type: 'price',
                                },
                            ]}
                        />
                    </div>
                )}
                <p>You can always negotiate the budget afterwards.</p>
            </>
        );
    }

    renderErrorMessage(type) {
        const typeOfChefOptions = this.isFineDining
            ? this.props.bespokeQuote.options.typeOfChefFineDining
            : this.props.bespokeQuote.options.typeOfChef;
        const { typeOfChef, guests, kids } = this.props.bespokeQuote.quote;
        let people = guests + kids,
            chefType = typeOfChefOptions[typeOfChef],
            max = Math.ceil(chefType.default[1] * people);

        switch (type) {
            case 'christmas':
                return (
                    <>
                        25th December is a premium day. The rate to book a chef
                        is on average £1-4k.
                        <br />
                        <br />
                        <span>
                            If your budget is lower, we recommend selecting
                            delivery where a chef can prep the entire roast,
                            trimmings etc and drop it off at your home. Average
                            is approx £500 for 8/10 people.
                        </span>
                    </>
                );
            case 'newyear':
                return (
                    <>
                        31st December is a premium day. The rate to book a chef
                        is on average £60-120pp.
                    </>
                );
            default:
                return (
                    <>
                        <p>
                            A {chefType?.label.toLowerCase()} for {people}{' '}
                            typically costs between £
                            {chefType?.price.toLocaleString()} - £
                            {max.toLocaleString()}. Please note, your budget
                            maybe too low and you might not get any chef
                            responses.
                        </p>
                    </>
                );
        }
    }

    renderError() {
        if (
            this.underBudget() &&
            (this.state.type == 'christmas' ||
                this.state.type == 'christmas-delivery' ||
                this.state.type == 'newyear')
        ) {
            return (
                <div
                    className={`mt-4 alert ${
                        this.state.type ? 'alert-danger' : 'alert-info'
                    }`}>
                    {this.renderErrorMessage(this.state.type)}
                </div>
            );
        }
        return <></>;
    }

    renderDescription() {
        let minBudget;
        let pricingMessage;
        const { typeOfEvent, guests, kids } = this.props.bespokeQuote.quote;

        let people = guests + kids;
        let min = 30 * people;
        let max = 60 * people;
        let message = `The pricing can be anywhere between £${min}-${max} range. The top of the range gets you more food, more variety of dishes, and access to premium chefs.`;

        if (
            (typeOfEvent == 3 && max < 300) ||
            (typeOfEvent == 2 && max < 200)
        ) {
            message = `Recommended budget for such an event is a minimum of £${
                typeOfEvent == 2 ? '200' : '300'
            }. The top of the range gets you more food, more variety of dishes, and access to premium chefs.`;
        } else if (
            (typeOfEvent == 3 && min < 300) ||
            (typeOfEvent == 2 && min < 200)
        ) {
            message = `The pricing can be anywhere between £${
                typeOfEvent == 2 ? '200' : '300'
            }-600 range. The top of the range gets you more food, more variety of dishes, and access to premium chefs.`;
        }

        switch (typeOfEvent) {
            case 2: // Delivery Platter.
                minBudget = false;
                pricingMessage = message;
                break;
            case 3: // Standing.
                minBudget = false;
                pricingMessage = message;
                break;
            case 4: // Meal Prep.
                minBudget = 200;
                pricingMessage =
                    'The pricing depends on the number of meals you would like the chef to cook, the quality and type of ingredients (e.g. organic, meat).';
                break;
            case 5: // Muli-day Chef Hire.
                minBudget = 200;
                pricingMessage =
                    'For a group of 10 people, preparing all meals could cost £600-1000 per day including groceries. Or if you would like to take care of the groceries, you can expect a £200-£500 day rate depending on type of chef.';
                break;
            case 7: // Cooking Class.
                minBudget = false;
                pricingMessage =
                    'Our chefs set their own minimum spend which ranges from £200 to £1000+ for more experienced chefs, e.g. Michelin-trained, MasterChefs. Please enter a rough total budget so we can match you to relevant chefs. You can then discuss number of participants, recipe etc. with the chef.';
                break;
            default:
                minBudget = false;
                pricingMessage = '';
        }

        return (
            <>
                <p>{pricingMessage}</p>
            </>
        );
    }

    updateSubscriptionType(value) {
        this.props.updateAttribute('subscriptionType', value);
    }

    renderSubscriptionOptions() {
        const { subscription } = this.props.bespokeQuote.options;
        const { typeOfEvent, subscriptionType, budget } =
            this.props.bespokeQuote.quote;

        return (
            <>
                {(typeOfEvent == 4 || typeOfEvent == 5) && budget > 0 ? (
                    <>
                        {this.renderHeading('I am interested in a:')}

                        <ul className="bespoke-quote__grid-2">
                            {_.map(subscription, (result) => {
                                return (
                                    <SelectablePill
                                        type="radio"
                                        disabled={false}
                                        idx={result.value}
                                        className="-blocks"
                                        name="subscription-type"
                                        onSelect={this.updateSubscriptionType.bind(
                                            this
                                        )}
                                        isSelected={checkIsSelected(
                                            result.value,
                                            subscriptionType
                                        )}>
                                        <span>{result.label}</span>
                                    </SelectablePill>
                                );
                            })}
                        </ul>
                    </>
                ) : (
                    <></>
                )}
            </>
        );
    }

    renderChefTypeOptions() {
        const { typeOfChef } = this.props.bespokeQuote.quote;
        const typeOfChefOptions = this.isFineDining
            ? this.props.bespokeQuote.options.typeOfChefFineDining
            : this.props.bespokeQuote.options.typeOfChef;

        return (
            <ul className="bespoke-quote__grid-3 p-0">
                {_.map(typeOfChefOptions, (option, idx) => {
                    return (
                        <SelectablePill
                            onSelect={(val) => {
                                this.props.updateAttribute('typeOfChef', val);

                                // if (val == 1) {
                                //     this.props.updateAttribute('conciergeInterest', null);
                                // }
                            }}
                            isSelected={checkIsSelected(idx, typeOfChef)}
                            disabled={false}
                            type="radio"
                            idx={idx}
                            name="who">
                            <span>
                                {option.label}
                                <br />
                                <small>
                                    <i
                                        className="fa-solid fa-square mr-2"
                                        style={{ fontSize: '10px' }}></i>
                                    <i>
                                        {idx == 3 ? (
                                            <span>avg £200+ pp</span>
                                        ) : (
                                            <span>
                                                avg £{option.default[0]}-
                                                {option.default[1]}pp
                                            </span>
                                        )}
                                    </i>
                                </small>
                                <br />
                                <small>
                                    <i
                                        className="fa-solid fa-square mr-2"
                                        style={{ fontSize: '10px' }}></i>
                                    <i>min spend £{option.min}</i>
                                </small>
                            </span>
                        </SelectablePill>
                    );
                })}
            </ul>
        );
    }

    updateConciergeInterest(value) {
        this.props.updateAttribute('conciergeInterest', value);
    }

    renderConciergeOptions() {
        const { concierge } = this.props.bespokeQuote.options;
        const { conciergeInterest, typeOfChef, budget, guests } =
            this.props.bespokeQuote.quote;
        let budgetPp = budget / guests;

        if (!is_node) {
            $(function () {
                $('[data-toggle="tooltip"]').tooltip();
            });
        }

        return (
            <>
                {typeOfChef > 1 && budget >= 100 && budgetPp >= 40 && (
                    <>
                        <h4 className="d-flex align-items-center">
                            Interested in a consultation with a Yhangry team
                            member?
                            <div
                                data-toggle="tooltip"
                                data-placement="bottom"
                                className="tooltip-icon ml-1"
                                title="A member of the team will get in touch with you via text or call to set up a video chat and talk you through your event specifications and chef options.">
                                <Info
                                    size={21}
                                    weight="regular"
                                    className="vertical-align-middle"
                                />
                            </div>
                        </h4>
                        <small
                            className="mb-2 d-block"
                            style={{ marginTop: '-7px' }}>
                            We will help hand-select the perfect chef for your
                            event.
                        </small>

                        <ul className="bespoke-quote__grid-2">
                            {_.map(concierge, (result) => {
                                return (
                                    <SelectablePill
                                        type="radio"
                                        disabled={false}
                                        idx={result.value}
                                        className="-blocks"
                                        name="concierge-interest"
                                        onSelect={this.updateConciergeInterest.bind(
                                            this
                                        )}
                                        isSelected={checkIsSelected(
                                            result.value,
                                            conciergeInterest
                                        )}>
                                        <span>{result.label}</span>
                                    </SelectablePill>
                                );
                            })}
                        </ul>
                    </>
                )}
            </>
        );
    }

    renderStep() {
        const { typeOfEvent, typeOfChef, guests, kids, suggestedBudget } =
            this.props.bespokeQuote.quote;
        let people = guests + kids;

        const typeOfChefOptions = this.isFineDining
            ? this.props.bespokeQuote.options.typeOfChefFineDining
            : this.props.bespokeQuote.options.typeOfChef;
        let chefType = typeOfChefOptions[typeOfChef];

        return (
            <>
                {this.noChefTypeOptions.includes(typeOfEvent) ? (
                    <>{this.renderDescription()}</>
                ) : (
                    <>{this.renderChefTypeOptions()}</>
                )}

                {typeOfChef &&
                    this.renderHeading(
                        `Based on <strong>${people}</strong> people, the range for a <strong>${chefType?.label.toLowerCase()}</strong> chef is <strong>£${suggestedBudget?.[0]?.toLocaleString()}-${suggestedBudget?.[1]?.toLocaleString()}${
                            this.isPpBudget ? 'pp' : ''
                        }</strong>`
                    )}

                {this.renderSlider()}
                {this.renderError()}
                <hr className="hr-transparent" />
                {this.renderSubscriptionOptions()}
                {/* {this.renderConciergeOptions()} */}
            </>
        );
    }
}

export default connect(TypeOfChefAndBudget);
