import React from 'react'

export default function ProgressBar({step, total}) {

    const percentage = ((step + 1) / total) * 100;

    return (
        <div className="progress w-100 bespoke-quote__progress">
            <div className="progress-bar bg-success" role="progressbar" style={{width: `${(percentage <= 100) ? percentage : 100 }%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    )
}
