import React, { Component } from 'react';

import { BespokeQuoteStep, connect } from './BespokeQuoteStep';
import Input from '@/elements/Input';

class Equipment extends BespokeQuoteStep {
    header = 'What does a 5 star experience look like?';
    stepName = 'equipment';
    icon = `<i className='fa-solid fa-carrot'></i>`;
    nextText = '';

    canDoNextStep() {
        const { quote } = this.props.bespokeQuote;

        // Check if typeOfEvent exists.
        if (!quote.equipment || !quote.tableware) return false;

        return true;
    }

    updateServingType(type) {
        this.props.updateAttribute('vibe', type, true);
    }

    renderAllOptions() {
        const { equipment: equipmentOptions, tableware: tablewareOptions } =
            this.props.bespokeQuote.options;

        const { equipment, tableware } = this.props.bespokeQuote.quote;

        return (
            <>
                <Input
                    inputType="checkbox"
                    label={'What Kitchen equipment does the venue have? '}
                    labelClass="fw-bold"
                    className="gap-6 [&_.form-styling:last-child]:lg:col-span-3"
                    optionClass="-button h-full"
                    cols={3}
                    options={_.map(equipmentOptions, (label, key) => {
                        return { label, value: key };
                    })}
                    value={equipment}
                    handleChange={(value) =>
                        this.props.updateAttribute('equipment', value)
                    }
                />

                <Input
                    inputType="checkbox"
                    label={'Do you have enough Tableware and Cutlery? '}
                    info="(Hint: chef doesn't bring this) "
                    labelClass="fw-bold"
                    className="gap-6 [&_.form-styling:last-child]:lg:col-span-3"
                    optionClass="-button h-full"
                    cols={3}
                    options={_.map(tablewareOptions, (label, key) => {
                        return { label, value: key };
                    })}
                    value={tableware}
                    handleChange={(value) =>
                        this.props.updateAttribute('tableware', value)
                    }
                />
            </>
        );
    }

    renderStep() {
        return <>{this.renderAllOptions()}</>;
    }
}

export default connect(Equipment);
