import { lazy, Suspense } from 'react';

function useLazyComponent(importStatement) {
    const LazyComponent = lazy(importStatement);

    return function WrappedComponent(props) {
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <LazyComponent {...props} />
            </Suspense>
        );
    };
}

export default useLazyComponent;
