import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectRecommendedChef } from '../../../actions/BespokeQuoteActions';
import ChefItem from '../../SearchChefs/ChefsList/ChefItem'

export default function RecommendedChefs({chefs}) {
    const bespokeQuote = useSelector(state => state.bespokeQuote);
    const dispatch = useDispatch();

    const renderChefItem = (chef) => {
        return (
            <div className={`recommended-chefs__item`}>
                <div className="form-group">
                    <input type="checkbox"
                        name="budget-assistance"
                            id="budget-assistance"
                            checked={bespokeQuote.confirmation.selectedChefs.includes(chef.id)}
                            />
                    <label className="form-check-label" htmlFor="budget-assistance" onClick={(ev) => {
                        ev.preventDefault()
                        ev.stopPropagation()
                        dispatch(selectRecommendedChef(chef.id))
                    }}>
                        <ChefItem chef={chef} />
                    </label>
                </div>
            </div>
        )
    }

    return (
        <div className="bespoke-quote__chefs">
            <form>
                <div className="recommended-chefs">
                    <h5>Other chefs we recommend.</h5>
                    {chefs.length > 1 ? 
                        <p>We suggest picking at least two chefs.</p>
                    :
                        <p>We suggest sending your quote to multiple chefs.</p>
                    }

                    <div className="recommended-chefs__list mt-3">
                        {chefs.map(chef => {
                            return renderChefItem(chef)
                        })}

                    </div>
                </div>
            </form>
        </div>
    )
}
