import React from 'react';
import { createInertiaApp } from '@inertiajs/react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import route from 'ziggy-js';
import { Ziggy } from '@/ziggy';
import Error from './Pages/Errors/Error';

createInertiaApp({
    title: (title) => title ?? 'yhangry - book a private chef for any occasion',
    resolve: (name) => {
        try {
            const pages = import.meta.glob('./Pages/**/*.jsx', {
                eager: false,
            });

            return pages[`./Pages/${name}.jsx`]();
        } catch (error) {
            return () => <Error />;
        }
    },
    setup({ el, App, props }) {
        global.route = (name, params, absolute, config = Ziggy) =>
            route(name, params, absolute, config);
        createRoot(el).render(<App {...props} />);
    },
    progress: {
        color: '#FF6854',
    },
});
