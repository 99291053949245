import React, { Component } from 'react'

import _ from 'lodash';
// import 'react-day-picker/lib/style.css';
// import 'rc-time-picker/assets/index.css';
import Autocomplete from 'react-autocomplete'
import Segment from '../../../utils/Segment';
import InfoBlock from '../../DirectQuote/Partials/InfoBlock';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';

class Location extends BespokeQuoteStep {
    header = 'Location';
    stepName = 'location';
    icon = `<i className="fa-solid fa-location-dot"></i>`;
    nextText = 'Food';

    state = {
        postcodes: [],
        collapse: false
    };

    delayAutocomplete = _.debounce((value) => {
        this.props.postcodeAutocomplete(value);
        this.props.getChefCount();
    }, 500);

    componentDidMount() {
        this._trackStep();
        const { bespokeQuote } = this.props;
        const { quote } = this.props.bespokeQuote;

        if (quote.postcode != '') {
            this.props.postcodeAutocomplete(quote.postcode);
        }
        
        if (quote.email != '') {
            axios.post('/segment/user', {email: quote.email})
                .then((response) => {
                    if (response.data && response.data != '') {
                        window.userId = response.data;

                        Segment.track('quote.start', {
                            step: bespokeQuote.step,
                            source: bespokeQuote.quote.source,
                            email: quote.email 
                        });
                    } else {
                        window.userId = quote.email;

                        Segment.identify({
                            email: quote.email 
                        })

                        Segment.track('quote.start', {
                            step: bespokeQuote.step,
                            source: bespokeQuote.quote.source,
                            email: quote.email 
                        });
                    }
                })
                .catch((error) => {
                    const data = error.response.data;

                    console.log('======================SEGMENT TRACKING ERROR');
                    console.log(data.message);
                });
        }
    }

    showForm = () => {
        this.setState({
            collapse: false
        })
    }

    canDoNextStep() {
        const { quote } = this.props.bespokeQuote;

        if (!quote.postcode || !this.props.bespokeQuote.isPostcodeValid || this.props.bespokeQuote.isPostcodeInternational) return false;

        return true;
    }

    validateStep() {
        return true;
    }

    renderPostcodeLookup() {
        const { bespokeQuote } = this.props,
            { quote, options } = bespokeQuote,
            value = quote.postcode;

        return (
            <>
                {this.renderHeading('The postcode or city of the event')}
                {this.state.collapse &&
                    <div onClick={() => this.showForm()}>
                        <p className="lead font-primary fw-medium user-options-text d-inline-block mb-0">
                            <span className="text-primary">{value ? value : 'Not Selected'}</span>
                            <a className="user-options-edit-link ml-3" onClick={() => this.showForm()}>Edit</a>
                        </p>
                    </div>
                }
                {!this.state.collapse &&
                    <div className="autocomplete">
                        <Autocomplete
                            getItemValue={(item) => item}
                            items={options.postcodes}
                            renderItem={(item, isHighlighted) =>
                                <div className='autocomplete-list-item'>
                                    {item}
                                </div>
                            }
                            value={value}
                            onChange={(event, value) => {
                                this.delayAutocomplete(value);
                                this.props.updateAttribute('postcode', value);
                            }}
                            onSelect={(value) => {
                                this.delayAutocomplete(value);
                                this.props.updateAttribute('postcode', value);
                            }}
                            inputProps={{ placeholder: 'Postcode or city' }}
                        />
                    </div>
                }
            </>
        )
    }

    renderStep() {
        const { bespokeQuote } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-3 text-center mb-2">
                        {this.renderPostcodeLookup()}
                    </div>

                    {bespokeQuote.quote.postcode && bespokeQuote.quote.postcode != '' && (!bespokeQuote.isPostcodeValid || bespokeQuote.isPostcodeInternational) &&
                        <InfoBlock 
                            icon={'yh-new-icon-Alert---Circle'}
                            description={`If you have an international query and you need a chef flying from the UK, please email info@yhangry.com`}
                        />
                    }
                </div>
            </>
        )
    }
}

export default connect(Location);