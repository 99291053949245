import axios from 'axios';

import {
    FETCH_DIETARY_REQUIREMENTS,
    FETCH_DIETARY_REQUIREMENTS_SUCCESS,
    FETCH_DIETARY_REQUIREMENTS_ERROR,
    UPDATE_SELECTED_DIETARY_REQUIREMENTS,
    SET_DIETARY_REQUIREMENTS
} from '../constants/ActionTypes';


/* Fetch dietaryRequirements success action */
export const fetchDietaryRequirementsSuccess = dietaryRequirements => {
    return {
        type: FETCH_DIETARY_REQUIREMENTS_SUCCESS,
        payload: dietaryRequirements,
    };
  };

/* Fetch dietaryRequirements error action */
export const fetchDietaryRequirementsError = error => ({
    type: FETCH_DIETARY_REQUIREMENTS_ERROR,
    payload: error,
});

/* Fetch dishes action */
export const fetchDietaryRequirements = () => (dispatch, getState) => {
    const url = `/dietary-requirements`;
    const request = axios.get(url);

    // Update reducer
    dispatch({
        type: FETCH_DIETARY_REQUIREMENTS,
        payload: request,
    });

    return request
        .then(response => {
            dispatch(fetchDietaryRequirementsSuccess(response));
        })
        .catch(err => {
            dispatch(fetchDietaryRequirementsError(err.response));
        });
};

/* Fetch dietaryRequirements update action */
export const updateSelectedDietaryRequirements = id => ({
    type: UPDATE_SELECTED_DIETARY_REQUIREMENTS,
    payload: id,
});

/* Fetch dietaryRequirements update action */
export const setDietaryRequirements = requirements => ({
    type: SET_DIETARY_REQUIREMENTS,
    payload: requirements,
});

export const getDietaryCost = guests => (dispatch, getState) => {
    const {dietaryRequirementOptions } = getState()

    if (!dietaryRequirementOptions.selected) return 0

    let total = 0
    dietaryRequirementOptions.selected.forEach((option) => {
        if (option.price < 1) return

        if (option.pricing_type == 'fixed') {
            total += parseFloat(option.price)
        } else {
            total += parseFloat(option.price * guests)
        }
    })

    return parseFloat(total);

}