const { ClipboardItem } = window;

(function ($) {
    $(function () {
        const shareButton = $('.js-share-button');
        const closeButton = $('.js-close-button');
        const copyButton = $('.js-copy');
        const socialShare = $('.js-share-social');

        shareButton.on('click', event => {
            if (navigator.share) {
                navigator.share({
                    title: $(document).attr('title'),
                    url: window.location.href
                }).then(() => {
                    console.log('Thanks for sharing!');
                })
                .catch(console.error);
            } else {
                $('#js-menu-modal').modal('show')
            }
        });

        closeButton.on('click', event => {
            $('#js-menu-modal').modal('hide')
        });

        // if (!navigator.clipboard) {
        //     copyButton.addClass('d-none')
        // }

        $('body').on('click', '.js-copy', function () {
            var btn = $(this),
                link = btn.attr('data-link');

            navigator.clipboard.writeText(link).then(function() {
                console.log('Async: Copying to clipboard was successful!');
                var ogText = btn.text();
                btn.text('Copied!');
                setTimeout(function () {
                    btn.text(ogText);
                }, 1000);
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
        });

        socialShare.on('click', event => {
            const link = event.target.getAttribute('data-link')
            const winTop = (screen.height / 2) - (600 / 2);
            const winLeft = (screen.width / 2) - (600 / 2);
            window.open(link, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+600+',height='+600);
        });

    });
  })(jQuery);
