import React from 'react';

import Input from '@/elements/Input';
import SelectablePill from '../Inputs/SelectablePill';
import { checkIsSelected } from '../../../utils/helpers';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';
class Allergies extends BespokeQuoteStep {
    header = 'What type of event are you planning?';
    stepName = 'occasion-type';
    nextText = '';

    constructor(props) {
        super(props);
        this.state = {
            typeSelected: false,
        };
        this.extraInputRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if the type has been selected and scroll to the input field.
        if (this.state.typeSelected && this.extraInputRef.current) {
            this.extraInputRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    canDoNextStep() {
        const { quote } = this.props.bespokeQuote;

        return !quote.type ? false : true;
    }

    updateType(value) {
        this.props.updateAttribute('type', value);
        this.setState({ typeSelected: true });
        this.props.nextStep();
    }

    renderOptions() {
        const { type } = this.props.bespokeQuote.quote;
        const typeOptions = this.props.bespokeQuote.options.type;

        return (
            <>
                <ul className="bespoke-quote__grid-2">
                    {_.map(typeOptions, (result) => {
                        return (
                            <SelectablePill
                                type="radio"
                                disabled={false}
                                idx={result.label}
                                className="-blocks"
                                name="subscription-type"
                                onSelect={this.updateType.bind(this)}
                                isSelected={checkIsSelected(
                                    result.label,
                                    type
                                )}>
                                <div className="d-flex">
                                    <div className="icon mr-3">
                                        {result.icon}
                                    </div>
                                    <div className="label ">{result.label}</div>
                                </div>
                            </SelectablePill>
                        );
                    })}
                </ul>
            </>
        );
    }

    renderExtra() {
        const { type } = this.props.bespokeQuote.quote;
        const { foodPreferences, typeOfEvent } = this.props.bespokeQuote.quote;

        if (type) {
            return (
                <div ref={this.extraInputRef}>
                    {this.renderHeading('Tell us more')}

                    <div className="form-group mt-3">
                        <Input
                            rows={typeOfEvent == 4 ? 5 : 4}
                            inputClass={`mt-2 ${
                                window.innerWidth <= 768 ? 'input-mobile' : ''
                            }`}
                            inputType="textarea"
                            value={foodPreferences}
                            info="Dietaries, vibe of event - casual, sharing etc, any equipment needed etc."
                            handleChange={(value) =>
                                this.props.updateAttribute(
                                    'foodPreferences',
                                    value
                                )
                            }
                            placeholder={
                                typeOfEvent == 4
                                    ? "E.g. We are looking for a chef to come once a week and cook 5 days worth of meals for me and my partner. We are looking for dairy-free, gluten-free dishes. Lunch, dinner, and perhaps breakfast pots or snacks as well. I'm happy to provide the groceries or for you to bring them. I have some recipes in mind I could share so you get an idea."
                                    : 'E.g. Two of us are gluten free. We would love sharing style tapas plates. We like mushrooms but want to avoid cheese. This is for a special 70th birthday. We are also looking for the chef to provide linens, cutlery, crockery and a server.'
                            }
                        />
                    </div>
                </div>
            );
        }
    }

    renderStep() {
        const { typeOfEvent } = this.props.bespokeQuote.quote;

        return typeOfEvent != 4 && this.renderOptions();
    }
}

export default connect(Allergies);
