import React from 'react'

export default function Protection({step, isLastStepVal, quoteChefCount}) {

    return (
        <div className="d-none d-lg-block mt-3">
            <h4>
                <span className="shield"><i className="fa-solid fa-shield-halved mr-2"></i></span>
                <span>Booking protection</span>
            </h4>
            <ul className='no-padding d-flex list-inline'>
                <li className='position-relative ml-4'>
                    <span className="fa-li"><i className="fa-regular fa-circle-check"></i></span>
                    <p>Protection from fraud</p>
                </li>
                <li className='position-relative ml-5'>
                    <span className="fa-li"><i className="fa-regular fa-circle-check"></i></span>
                    <p>Free 7 day cancellations</p>
                </li>
                <li className='position-relative ml-5'>
                    <span className="fa-li"><i className="fa-regular fa-circle-check"></i></span>
                    <p>Insurance cover</p>
                </li>
            </ul>
        </div>
    )

    // Keeping for legacy checks for now
    // TODO - remove once we're happy

    let showChefCount = false;

    if (step == 2 && isLastStepVal || step == 3 && !isLastStepVal) {
        showChefCount = true;
    }

    return (
        <div className="bespoke-quote__protection">
            <div className={`${(showChefCount == true) ? 'flex-container' : ''}`}>
                {showChefCount && (
                    <div className="fixed matches-desktop">
                        <h4>
                            <span className="mr-2"><i className="fa-solid fa-envelope-circle-check"></i></span>
                            Your {(quoteChefCount > 0) ? quoteChefCount : ''} best matches are ready!
                        </h4>
                        <h5>
                            We'll save your quote and email you a link.
                        </h5>
                        <p>
                            Enter your details to see your matches. We'll also notify you when a new quote comes in.
                        </p>
                    </div>
                )}
                <div className="fixed block bg-stone p-3">
                    <div className="accordion modified-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div className="card bg-stone">
                            <div className="card-header" role="tab" id="headingOne1">
                                <a className="collapsed btn-nav-accordion" data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne1"
                                    aria-expanded="false" aria-controls="collapseOne1">
                                    <h4>
                                        <span className="shield"><i className="fa-solid fa-shield-halved"></i></span>
                                        <span>Booking protection</span>
                                        <i className="fa-solid fa-chevron-down accordion-expand-icon ml-2"></i>
                                    </h4>
                                </a>
                            </div>
                            <div id="collapseOne1" className="collapse" role="tabpanel" aria-labelledby="headingOne1" data-parent="#accordionEx">
                                <div className="card-body">
                                    <ul className="fa-ul" style={{"marginLeft": "-7px"}}>
                                        <div className="block-content">
                                            <li>
                                                <span className="fa-li"><i className="fa-regular fa-circle-check"></i></span>
                                                <p>Secure online payments</p>
                                            </li>
                                            <li>
                                                <span className="fa-li"><i className="fa-regular fa-circle-check"></i></span>
                                                <p>Protection from fraud</p>
                                            </li>
                                        </div>
                                        <div className="block-content">
                                            <li>
                                                <span className="fa-li"><i className="fa-regular fa-circle-check"></i></span>
                                                <p>Free 7 day cancellations</p>
                                            </li>
                                            <li>
                                                <span className="fa-li"><i className="fa-regular fa-circle-check"></i></span>
                                                <p>Support available Mon-Sun</p>
                                            </li>
                                        </div>
                                        <div className="block-content">
                                            <li>
                                                <span className="fa-li"><i className="fa-regular fa-circle-check"></i></span>
                                                <p>Insurance cover</p>
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showChefCount && (
                    <div className="fixed matches-mobile mt-4">
                        <h4>
                            <span className="mr-2"><i className="fa-solid fa-envelope-circle-check"></i></span>
                            Your {(quoteChefCount > 0) ? quoteChefCount : ''} best matches are ready!
                        </h4>
                        <h5>
                            We'll save your quote and email you a link.
                        </h5>
                        <p>
                            Enter your details to see your matches. We'll also notify you when a new quote comes in.
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}