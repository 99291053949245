import React from 'react';
import { closeQuote } from '../util/helpers';
import { aws } from '@/config';

export default function Representative({
    step,
    isLastStepVal,
    quoteChefCount,
    embed,
}) {
    let content =
        "Hi! I'm Akifa, your bookings specialist (and a real person!)";

    if (step > 0) {
        content =
            'Give us a few details, and chefs will send you personalised menus 🥳';
    }

    if (step == 1) {
        content = 'Give us a few details to see chefs, menus and prices. It will take <2min.';
    }

    if (step > 13) {
        content = `We have ${
            quoteChefCount > 0 ? quoteChefCount : ''
        } chefs available in your area.`;
    }

    if (step == 17) {
        content = 'Thanks for your quote, our chefs will respond back shortly.';
    }

    return (
        <div className="d-flex w-100 bespoke-quote__representative items-center justify-between">
            <div className="flex-grow-1">
                <div
                    className="img-thumbnail rounded-circle image"
                    title="representative"
                    style={{
                        background:
                            'url(' +
                            aws.url +
                            '/images/representative.png) center center / cover no-repeat',
                    }}></div>
                {!embed && <div className="copy">{content}</div>}
                {embed && (
                    <div className="copy">
                        <h1>{content}</h1>
                    </div>
                )}
            </div>
            {!embed && (
                <div>
                    <button
                        type="button"
                        className="modal-close-button ml-3"
                        data-dismiss="bespokeQuoteModal"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => closeQuote('cross')}>
                        <i className="yh-new-icon-Cross text-2xl"></i>
                    </button>
                </div>
            )}
        </div>
    );
}
