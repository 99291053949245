import Swiper, { Navigation, Pagination } from 'swiper'
// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

  (function ($) {
    $(function () { 

        var swiper = new Swiper(".simple-carousel", {
            pagination: {
              el: ".swiper-pagination",
              type: "fraction",
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });

      $('.slide').carousel({
        interval: 1,
        ride: false,
        pause: false
      })

    });
  })(jQuery);
