import React, { Component, Suspense, lazy } from 'react';
import ReduxContainer from '@/ReduxContainer';

// import BespokeQuote from './BespokeQuote';
// const BespokeQuote = lazy(() => import('./BespokeQuote'));
import BespokeQuote from './BespokeQuote';

class BespokeQuoteContainer extends Component {
    render() {
        return (
            <ReduxContainer>
                <Suspense fallback={<></>}>
                    <BespokeQuote
                        autoShow={
                            this.props._autoShow ? this.props._autoShow : false
                        }
                        autoShowDelay={this.props._autoShowDelay}
                        embed={this.props._embed}
                        chefId={this.props._chefId}
                        minSpend={this.props._minSpend}
                        minPp={this.props._minPp}
                        postcode={this.props._postcode}
                        _setMenuId={this.props._setMenuId}
                    />
                </Suspense>
            </ReduxContainer>
        );
    }
}

export default BespokeQuoteContainer;
