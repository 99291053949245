(function ($) {
    $(function () {
        // set hamburger classes
        $(".js-section-toggle").on('click', function(ev) {
            ev.preventDefault();

            console.log('clicked')

            let $el = $(this),
                collapse = $el.attr('data-collapse'),
                expand = $el.attr('data-expand');

            $(collapse).addClass('d-none')
            $(expand).removeClass('d-none')
        })

        $(".js-toggle-class").on('click', function(ev) {
            ev.preventDefault();

            console.log('clicked')

            let $el = $(this),
                collapse = $el.attr('data-section'),
                expand = $el.attr('data-class');

            $(collapse).addClass(expand)
        })

        // Javascript to enable link to tab
        var hash = location.hash.replace(/^#/, '');  // ^ means starting, meaning only match the first hash
        if (hash) {
            $('.nav-tabs a[href="#' + hash + '"]').tab('show');
        } 

        // Change hash for page-reload
        $('.nav-tabs a').on('shown.bs.tab', function (e) {
            window.location.hash = e.target.hash;
        })

        const $instantBook = $('.js-instant-book-toggle'),
            $requestBook = $('.js-request-book-toggle')

        $instantBook.on('change', function(ev) {
            let checked = $(this).is(':checked')
            if (checked) $requestBook.prop('checked', false);
            
        })
        $requestBook.on('change', function(ev) {
            let checked = $(this).is(':checked')
            if (checked) $instantBook.prop('checked', false);
        })
       

    });
})(jQuery);
