import React, { Component } from 'react';

import SelectablePill from '../Inputs/SelectablePill';
import { checkIsSelected } from '../../../utils/helpers';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';

class TypeOfService extends BespokeQuoteStep {
    header = 'Select the type of service';
    stepName = 'service-type';
    icon = `<i className='fa-solid fa-carrot'></i>`;
    nextText = '';

    canDoNextStep() {
        const { quote } = this.props.bespokeQuote;

        // Check if typeOfEvent exists.
        if (!quote.typeOfEvent) return false;

        return true;
    }

    updateServingType(type) {
        this.props.updateAttribute('typeOfChef', false);
        this.props.updateAttribute('typeOfEvent', type.id);
        this.props.updateAttribute('servingType', type.label);
        this.props.updateAttribute('subscriptionType', false);
        // this.props.updateAttribute('conciergeInterest', null);

        if (type.id === 4) {
            this.props.updateAttribute('type', 'Other');
        }

        this.props.nextStep();
    }

    renderAllOptions() {
        let { servingType } = this.props.bespokeQuote.options;
        const selectedServingType = this.props.bespokeQuote.quote.servingType;

        return (
            <>
                {this.renderHeading('Service required')}

                <ul className="bespoke-quote__grid-3">
                    {_.map(servingType, (type) => {
                        return (
                            <SelectablePill
                                type="radio"
                                idx={type.label}
                                disabled={false}
                                className="-blocks"
                                name="service-type"
                                onSelect={() => this.updateServingType(type)}
                                isSelected={checkIsSelected(
                                    type.label,
                                    selectedServingType
                                )}>
                                <span>{type.label}</span>
                            </SelectablePill>
                        );
                    })}
                </ul>
            </>
        );
    }

    renderStep() {
        const selectedTypeOfEvent = this.props.bespokeQuote.quote.typeOfEvent;

        return <>{this.renderAllOptions()}</>;
    }
}

export default connect(TypeOfService);
