import React from 'react';

import Input from '@/elements/Input';
import SelectablePill from '../Inputs/SelectablePill';
import { checkIsSelected } from '../../../utils/helpers';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';

class Dietary extends BespokeQuoteStep {
    header = 'What dietary requirements does your group have?';
    stepName = 'dietary';
    icon = `<i className="fa-solid fa-carrot"></i>`;
    nextText = 'Urgency';

    componentDidMount() {
        this._trackStep();
        this.props.fetchDietaryRequirements();
    }

    updateDietary(value) {
        this.props.updateAttribute('dietaryPreferences', value, true);
    }

    renderDietaryOptions() {
        const selectedDietaryPreferences =
            this.props.bespokeQuote.quote.dietaryPreferences;
        let { dietaryPreferences } = this.props.bespokeQuote.options;

        return (
            <>
                {this.renderHeading(
                    'Select all that apply. You can edit them anytime.'
                )}

                <ul className={`bespoke-quote__grid-3 -sm-2`}>
                    {_.map(dietaryPreferences, (dietary) => {
                        return (
                            <SelectablePill
                                type="checkbox"
                                idx={dietary.id}
                                disabled={false}
                                className="-blocks"
                                name="type-of-event"
                                label={dietary.label}
                                onSelect={this.updateDietary.bind(this)}
                                isSelected={checkIsSelected(
                                    dietary.id,
                                    selectedDietaryPreferences
                                )}></SelectablePill>
                        );
                    })}
                </ul>
            </>
        );
    }

    renderExtra() {
        // For variant B only

        const { foodPreferences, typeOfEvent } = this.props.bespokeQuote.quote;

        return (
            <div>
                {this.renderHeading('Tell us more')}

                <div className="form-group mt-3">
                    <Input
                        rows={typeOfEvent == 4 ? 5 : 4}
                        inputClass={`mt-2 ${
                            window.innerWidth <= 768 ? 'input-mobile' : ''
                        }`}
                        inputType="textarea"
                        value={foodPreferences}
                        info="Dietaries, vibe of event - casual, sharing etc, any equipment needed etc."
                        handleChange={(value) =>
                            this.props.updateAttribute('foodPreferences', value)
                        }
                        placeholder={
                            typeOfEvent == 4
                                ? "E.g. We are looking for a chef to come once a week and cook 5 days worth of meals for me and my partner. We are looking for dairy-free, gluten-free dishes. Lunch, dinner, and perhaps breakfast pots or snacks as well. I'm happy to provide the groceries or for you to bring them. I have some recipes in mind I could share so you get an idea."
                                : 'E.g. Two of us are gluten free. We would love sharing style tapas plates. We like mushrooms but want to avoid cheese. This is for a special 70th birthday. We are also looking for the chef to provide linens, cutlery, crockery and a server.'
                        }
                    />
                </div>
            </div>
        );
    }

    renderStep() {
        return (
            <>
                {this.renderDietaryOptions()}
                <hr className="hr-transparent" />
                {this.renderExtra()}
            </>
        );
    }
}

export default connect(Dietary);
