import React, { Component } from 'react';
import { connect as reduxConnect } from 'react-redux';

// import Config from 'Config';
import { bindActionCreators } from 'redux';
import Segment from '../../../utils/Segment';
import {
    nextStep,
    prevStep,
    updateAttribute,
    postcodeAutocomplete,
    submitQuote,
    getChefCount,
    getExistingQuote,
    updateExistingQuote,
} from '../../../actions/BespokeQuoteActions';
import { getSupplyDemand } from '../../../actions/BasketActions';
import { fetchDietaryRequirements } from '../../../actions/DietaryRequirementsActions';
import { closeQuote } from '../util/helpers';

export class BespokeQuoteStep extends Component {
    header = null;
    stepName = null;
    skipStep = false;
    stepsToSkipNext = false;
    stepsToSkipPrev = false;

    componentDidMount() {
        this._trackStep();
        if (this.props.stepsToSkipNext)
            this.stepsToSkipNext = this.props.stepsToSkipNext;

        if (this.props.stepsToSkipPrev)
            this.stepsToSkipPrev = this.props.stepsToSkipPrev;
    }

    componentDidUpdate(prevProps) {
        const { bespokeQuote, basketData } = this.props;

        // Pre populate meal date from basket
        if (
            basketData?.mealDate !== prevProps.basketData?.mealDate &&
            bespokeQuote?.quote?.mealDate == ''
        ) {
            this.props.updateAttribute('mealDate', basketData?.mealDate);
        }
        // Pre populate postcode from basket
        if (
            basketData?.postcode !== prevProps.basketData?.postcode &&
            bespokeQuote?.quote?.postcode == ''
        ) {
            this.props.updateAttribute('postcode', basketData?.postcode);
        }
    }

    _trackStep() {
        const { step, quote } = this.props.bespokeQuote;

        let trackingData = {};
        if (quote) {
            _.map(quote, (value, key) => {
                if (value != '' && value != null && value != undefined) {
                    trackingData[key] = value;
                }
            });
        }

        // if (step != 0 && !this.props.isLastStep) {
        if (step != 0) {
            Segment.track('quote.step', {
                step: this.props.bespokeQuote.step + 1,
                ...trackingData,
            });

            Segment.track('quote.step_' + this.stepName, trackingData);
        }
    }

    validateStep() {
        return true;
    }

    canDoNextStep() {
        return true;
    }

    nextStep() {
        const { quote } = this.props.bespokeQuote;

        if (!this.validateStep()) return false;

        if (this.props.isLastStep && quote.existingQuote != 1) {
            this.props.submitQuote();
        } else if (this.props.isLastStep && quote.existingQuote == 1) {
            this.props.updateExistingQuote();
        } else {
            this.props.nextStep(this.props.stepsToSkipNext);
        }
    }

    prevStep() {
        this.props.prevStep(this.stepsToSkipPrev);
    }

    renderHeading(title) {
        return <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>;
    }

    renderStep() {}

    renderStepFooter() {
        const { bespokeQuote } = this.props;
        const { quote, existingQuote } = bespokeQuote;

        if (bespokeQuote.hasSubmitted) {
            return (
                <div className="bespoke-quote__footer d-flex justify-content-between align-items-center w-100">
                    <span className="d-flex flex-grow-1 justify-content-end">
                        {!bespokeQuote.embed && (
                            <button
                                type="button"
                                className="btn btn--primary"
                                data-dismiss="bespokeQuoteModal"
                                data-dismiss="modal"
                                aria-label="Close">
                                Close
                            </button>
                        )}
                    </span>
                </div>
            );
        }

        var ctaCopy = this.props.isLastStep ? 'Find matching chefs' : 'Next';

        if (bespokeQuote.step == 7 && bespokeQuote.quote.dietary == '') {
            ctaCopy = 'Skip';
        } else if (
            bespokeQuote.step == 0 &&
            quote.chefId &&
            existingQuote &&
            quote.existingQuote == 1
        ) {
            ctaCopy = 'Add chef';
        }

        return (
            <div className="bespoke-quote__footer d-flex justify-content-between align-items-center w-100">
                {bespokeQuote.step == 0 ||
                    (this.props.isFirstStep && !bespokeQuote.embed && (
                        <button
                            className="btn btn-back"
                            data-dismiss="bespokeQuoteModal"
                            onClick={() => closeQuote('just browsing')}>
                            I'm just browsing
                        </button>
                    ))}
                {bespokeQuote.step == 0 ||
                    (this.props.isFirstStep && bespokeQuote.embed && (
                        <a
                            href={`${Config.sub_dir}/chefs`}
                            className="btn btn-back">
                            I'm just browsing
                        </a>
                    ))}
                {bespokeQuote.step > 0 && !this.props.isFirstStep && (
                    <button
                        className="btn btn-back"
                        onClick={this.prevStep.bind(this)}>
                        Back
                    </button>
                )}
                <span className="d-flex flex-grow-1 justify-content-end">
                    <button
                        className="btn btn--primary"
                        onClick={this.nextStep.bind(this)}
                        disabled={
                            !this.canDoNextStep() || bespokeQuote.loading
                        }>
                        {ctaCopy}
                    </button>
                </span>
            </div>
        );
    }

    render() {
        const { updateExistingQuote, quote } = this.props.bespokeQuote;
        const { emailMethod } = quote;

        if (updateExistingQuote) {
            this.header = 'Chef has been invited to your quote';
        }

        if (emailMethod && emailMethod == 'account-login') {
            this.header = 'Check your email on this device';
        }

        return (
            <React.Fragment>
                <div className="modal-body">
                    <div className="bespoke-quote">
                        <div className="bespoke-quote__step">
                            {this.header && (
                                <h3 className="text-center mb-5 secondaryHeader">
                                    {/* {this.icon && 
                                        <span dangerouslySetInnerHTML={{ __html: this.icon }} className="mr-2 step-title"></span>
                                    } */}
                                    {emailMethod &&
                                        emailMethod == 'account-login' && (
                                            <i className="fa-solid fa-envelope-circle-check mr-2"></i>
                                        )}

                                    {this.header}
                                </h3>
                            )}
                            {this.renderStep()}

                            {this.props.bespokeQuote.error && (
                                <div
                                    className="alert alert-danger"
                                    role="alert">
                                    {this.props.bespokeQuote.error}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {this.renderStepFooter() !== false && (
                    <div className="modal-footer">
                        {this.renderStepFooter()}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ bespokeQuote, userData, basketData }) => {
    return { bespokeQuote, userData, basketData };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            nextStep,
            prevStep,
            updateAttribute,
            fetchDietaryRequirements,
            postcodeAutocomplete,
            submitQuote,
            getChefCount,
            getSupplyDemand,
            getExistingQuote,
            updateExistingQuote,
        },
        dispatch
    );
}

export function connect(Component) {
    return reduxConnect(mapStateToProps, mapDispatchToProps)(Component);
}

export default connect(BespokeQuoteStep);
