(function($) {
    $(function() {
      $(".checkbox-menu").on("change", "input[type='checkbox']", function() {
          $(this)
              .closest("li")
              .toggleClass("active", this.checked);
      });

      $(document).on("click", ".allow-focus", function(e) {
          e.stopPropagation();
      });
    });
})(jQuery);
