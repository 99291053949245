import React, { Component } from 'react';

import SelectablePill from '../Inputs/SelectablePill';
import { checkIsSelected } from '../../../utils/helpers';
import { BespokeQuoteStep, connect } from './BespokeQuoteStep';
import Input from '@/elements/Input';
import SelectableImage from '../Inputs/SelectableImage';
import { aws } from '@/config';

class Vibe extends BespokeQuoteStep {
    header = 'What does a 5 star experience look like?';
    stepName = 'vibe';
    icon = `<i className='fa-solid fa-carrot'></i>`;
    nextText = '';

    canDoNextStep() {
        const { quote } = this.props.bespokeQuote;

        // Check if typeOfEvent exists.
        if (!quote.vibe || !quote.foodPresentation || !quote.foodQuantity)
            return false;

        return true;
    }

    updateServingType(type) {
        this.props.updateAttribute('vibe', type, true);

        // this.props.nextStep();
    }

    renderAllOptions() {
        const {
            vibe: vibeOptions,
            food_presentation: foodPresentationOptions,
            food_quantity: foodQuantityOptions,
        } = this.props.bespokeQuote.options;

        const { vibe, foodQuantity, foodPresentation } =
            this.props.bespokeQuote.quote;

        // convet the object to an array
        const foodPresentationArray = Object.keys(foodPresentationOptions).map(
            (key) => key
        );

        return (
            <>
                <Input
                    inputType="checkbox"
                    label={'Vibe'}
                    labelClass="fw-bold"
                    className="[&_.input-grid]:!flex"
                    optionClass="yh-pill"
                    name="vibe"
                    isMulti={true}
                    options={_.map(vibeOptions, (label, key) => {
                        return { label, value: key };
                    })}
                    value={vibe}
                    handleChange={(value) =>
                        this.props.updateAttribute('vibe', value, true)
                    }
                />

                <div className="form-group">
                    <label className="fw-bold">Food presentation</label>
                    <ul className="selectable-image-list">
                        {_.map(foodPresentationOptions, (label, key) => {
                            return (
                                <SelectableImage
                                    onSelect={(val) => {
                                        console.log('val', { val });
                                        this.props.updateAttribute(
                                            'foodPresentation',
                                            val
                                        );
                                    }}
                                    isSelected={checkIsSelected(
                                        foodPresentation,
                                        key
                                    )}
                                    disabled={false}
                                    type="radio"
                                    label={label}
                                    idx={key}
                                    image={`${aws.url}/bespoke-quote/food-preferences/${key}.jpg`}
                                    name="food-presentation"
                                />
                            );
                        })}
                    </ul>
                </div>

                <Input
                    inputType="checkbox"
                    label={'Food quantity'}
                    labelClass="fw-bold"
                    // className="dropdown-item"
                    // optionClass=" check styles"
                    className="gap-6"
                    optionClass="-button h-full"
                    cols={3}
                    options={_.map(foodQuantityOptions, (label, key) => {
                        return { label, value: key };
                    })}
                    value={foodQuantity}
                    handleChange={(value) =>
                        this.props.updateAttribute('foodQuantity', value)
                    }
                />
            </>
        );
    }

    renderStep() {
        return <>{this.renderAllOptions()}</>;
    }
}

export default connect(Vibe);
