(function ($) {
    $(function () {
        // set hamburger classes
        $('#main-menu')
            .on('show.bs.collapse', function () {
                $('#account-menu').collapse('hide').removeClass('show');
                $('.hamburger').addClass('is-active');
                $('#header').addClass('menu-is--open');
            })
            .on('hidden.bs.collapse', function () {
                $('.hamburger').removeClass('is-active');
                $('#header').removeClass('menu-is--open');
            });
        $('#account-menu')
            .on('show.bs.collapse', function () {
                $('#main-menu').collapse('hide').removeClass('show');
                $('.hamburger').addClass('is-active');
                $('#header').addClass('menu-is--open');
            })
            .on('hidden.bs.collapse', function () {
                $('.hamburger').removeClass('is-active');
                $('#header').removeClass('menu-is--open');
            });

        // $('.js-toggle-offcanvas').on('click', () => {
        //     $('.js-offcanvas-menu').addClass('show');
        //     $('.js-offcanvas-menu').after(
        //         '<div class="modal-backdrop fade show js-backdrop-offcanvas"></div>'
        //     );
        //     $('body').css({ overflow: 'hidden', paddingRight: 0 });
        // });

        // $('.js-close-offcanvas, .js-open-signin').on('click', () => {
        //     $('.js-offcanvas-menu').removeClass('show');
        //     $('.js-backdrop-offcanvas').remove();
        //     $('body').css({ overflow: '', paddingRight: '' });
        // });
    });
})(jQuery);

$(document).on('click', '.js-backdrop-offcanvas', function () {
    $('.js-offcanvas-menu').removeClass('show');
    $('.js-backdrop-offcanvas').remove();
    $('body').css({ overflow: '', paddingRight: '' });
});

$(document).on('click', '.js-toggle-offcanvas', function () {
    $('.js-offcanvas-menu').addClass('show');
    $('.js-offcanvas-menu').after(
        '<div class="modal-backdrop fade show js-backdrop-offcanvas"></div>'
    );
    $('body').css({ overflow: 'hidden', paddingRight: 0 });
});

$(document).on('click', '.js-close-offcanvas, .js-open-signin', function () {
    $('.js-offcanvas-menu').removeClass('show');
    $('.js-backdrop-offcanvas').remove();
    $('body').css({ overflow: '', paddingRight: '' });
});
