(function ($) {
    $(function () { 

        $('.js-reject-booking').on('click', function(ev) {

            const form = $(this).closest('form');
            const textarea = form.find('textarea')
            const value = $(textarea).val()
        
            if (!value) {
                ev.preventDefault();
                if (form.find('.invalid-feedback').length < 1) {
                    textarea.after('<span class="invalid-feedback" role="alert"><strong>Please enter a reason</strong></span>')
                    textarea.addClass('is-invalid')
                }
            }
        });

    });
  })(jQuery);
